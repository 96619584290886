import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';

const animationEnd = {
  transform: 'scale(1)',
  opacity: '1',
};

export const dataChange: AnimationTriggerMetadata = trigger('dataChange', [
  transition('done => bottom', [
    style({
      transform: 'translateY(768px) scale(0.65)',
      opacity: '0.7',
    }),
    animate('0.35s ease', style(animationEnd)),
  ]),
  transition('done => top', [
    style({
      transform: 'translateY(-768px) scale(0.65)',
      opacity: '0.7',
    }),
    animate('0.35s ease', style(animationEnd)),
  ]),
]);

@Component({
  selector: 'altru-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss'],
  animations: [dataChange],
})
export class NavigationModalComponent {
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;

  @Input()
  hasPreviousItem = false;

  @Input()
  hasNextItem = false;

  @Output()
  prevItemClick: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  nextItemClick: EventEmitter<Event> = new EventEmitter<Event>();

  animateDirection: 'top' | 'bottom' | 'done' = 'done';

  prevClick() {
    if (this.hasPreviousItem) {
      this.prevItemClick.emit();
      this.animateDirection = 'top';
    }
  }

  nextClick() {
    if (this.hasNextItem) {
      this.nextItemClick.emit();
      this.animateDirection = 'bottom';
    }
  }

  idlePeriod = 100;
  animationDuration = 1000;
  lastAnimation = 0;
  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    let delta = 0;
    if (event.deltaY) {
      delta = event.deltaY;
    }
    const timeNow = new Date().getTime();

    if (
      timeNow - this.lastAnimation <
      this.idlePeriod + this.animationDuration
    ) {
      event.preventDefault();
      return;
    }
    if (delta < 0) {
      this.prevClick();
    } else {
      this.nextClick();
    }
    this.lastAnimation = timeNow;
  }

  touchStart;
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStart = event.changedTouches[0].clientY;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const te = event.changedTouches[0].clientY;
    if (this.touchStart > te + 5) {
      this.nextClick();
    } else if (this.touchStart < te - 5) {
      this.prevClick();
    }
  }
}
