import { createAction, props } from '@ngrx/store';
import { breakpointSize } from './ui.model';

export const modifyDialogState = createAction(
  '[UI] Modify dialog state',
  props<{ visibility: boolean }>()
);

export const modifySidebarState = createAction(
  '[UI] Modify sidebar state',
  props<{ visibility: boolean }>()
);

export const modifyBreakpointState = createAction(
  '[UI] Modify breakpoint state',
  props<{ size: breakpointSize }>()
);
