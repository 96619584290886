import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OembedService {
  alternateLinkElement: HTMLLinkElement;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      this.initializeAlternatelLinkElement();
    }
  }

  private initializeAlternatelLinkElement() {
    this.alternateLinkElement = this.document.createElement('link');
    this.alternateLinkElement.setAttribute('rel', 'alternate');
    this.alternateLinkElement.setAttribute('href', '');
    this.alternateLinkElement.setAttribute('type', 'application/json+oembed');
  }

  setAlternateLink(title: string, link: string) {
    if (this.alternateLinkElement) {
      this.document.head.appendChild(this.alternateLinkElement);
      this.alternateLinkElement.setAttribute('href', link);
      this.alternateLinkElement.setAttribute('title', title);
    }
  }
}
