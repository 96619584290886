import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

export type SocialMetaTags = {
  title: string;
  og_image: string;
  og_type: string;
  og_url: string;
  og_site_name: string;
  og_publish_date: string;
  og_updated_time: string;
  og_description: string;
  og_author: string;
  og_video: string;
  og_video_secure_url: string;
  og_video_type: string;
  og_video_height: string;
  og_video_width: string;
  twitter_card: string;
  twitter_site: string;
  twitter_player: string;
  twitter_player_height: string;
  twitter_player_width: string;
};

@Injectable()
export class MetaService {
  constructor(private metaService: Meta) {}

  setSocialTags(data: SocialMetaTags): void {
    this.metaService.addTags([
      { property: 'og:title', content: data.title },
      { property: 'og:description', content: data.og_description },
      { property: 'og:author', content: data.og_author },
      { property: 'og:image', content: data.og_image },
      { property: 'og:type', content: data.og_type },
      { property: 'og:url', content: data.og_url },
      { property: 'og:site_name', content: data.og_site_name },
      { property: 'og:publish_date', content: data.og_publish_date },
      { property: 'og:updated_time', content: data.og_updated_time },
      { property: 'og:video', content: data.og_video },
      { property: 'og:video:secure_url', content: data.og_video_secure_url },
      { property: 'og:video:type', content: data.og_video_type },
      { property: 'og:video:height', content: data.og_video_height },
      { property: 'og:video:width', content: data.og_video_width },
      { name: 'twitter:card', content: data.twitter_card },
      { name: 'twitter:site', content: data.twitter_site },
      { name: 'twitter:player', content: data.twitter_player },
      { name: 'twitter:player:height', content: data.twitter_player_height },
      { name: 'twitter:player:width', content: data.twitter_player_width },
    ]);
  }
}
