import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { VideoPopupStatusMessage } from 'altru-types';

@Injectable()
export class FeedService {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  focusExternalCloseButton(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    window.parent.postMessage('Focus close button', '*');
  }

  focusLastElement(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const lastLikeButton: HTMLElement = Array.prototype.slice
      .call(document.querySelectorAll('altru-answer-card:not(.hidden) .likes'))
      .splice(-1)
      .pop();

    if (lastLikeButton) {
      lastLikeButton.focus();
    }
  }

  sendPageHeight(height?: number): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const heightToSend =
      height || document.getElementsByTagName('html')[0].scrollHeight;
    window.parent.postMessage({ iframeHeight: heightToSend }, '*');
  }

  toggleVideoPopup(value: VideoPopupStatusMessage['popup']): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const payload: VideoPopupStatusMessage = { popup: value };
    window.parent.postMessage(payload, '*');
  }
}
