import * as R from 'ramda';

import { Client } from 'altru-types';
import * as ClientActions from './client.actions';

type Action = ClientActions.All;

export const INITIAL_STATE: Client | null = null;

export function clientReducer(
  state: Client | null = INITIAL_STATE,
  action: Action
): Client | null {
  switch (action.type) {
    case ClientActions.SET:
      return R.clone(action.payload as Client);
    default:
      return state;
  }
}
