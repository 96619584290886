/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { Template } from 'altru-types';

export const SET = '[Template] Set';

export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: Template) {}
}

export type All = Set;
