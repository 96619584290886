import { Component } from '@angular/core';

@Component({
  selector: 'altru-old-browsers-advice',
  templateUrl: './old-browsers-advice.component.html',
  styleUrls: ['./old-browsers-advice.component.scss'],
})
export class OldBrowsersAdviceComponent {
  constructor() {}
}
