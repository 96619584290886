import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
  Renderer2,
} from '@angular/core';

import { Template } from 'altru-types';
import { TemplateService } from '@services';
import { first, skipWhile } from 'rxjs/operators';

@Injectable()
export class HelperService {
  constructor(
    private rendererFactory: RendererFactory2,
    private templateService: TemplateService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  getBrowserName(): string | undefined {
    const ua: string = isPlatformBrowser(this.platformId)
      ? navigator.userAgent
      : 'none';
    let tem: RegExpMatchArray | RegExpExecArray | undefined[];
    const M: any =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE';
    }

    if (M[1] === 'Chrome') {
      return 'Chrome';
    }

    if (M[1] === 'Safari') {
      return 'Safari';
    }

    return undefined;
  }

  setIEStyles(css: string, template?: Template): any {
    if (isPlatformBrowser(this.platformId) && this.getBrowserName() === 'IE') {
      let styles = css;

      if (template) {
        styles = styles.concat(`
          altru-filters-selector .header .actions .close-button {
            color: ${template.styles['question-title']} !important;
            background-color: ${template.styles['search-bar']} !important;
          }

          altru-filters .tag {
            background-color: ${template.styles.pills} !important;
          }

          altru-tag {
            color: ${template.styles.pills} !important;
          }

          altru-tag.active {
            background-color: ${template.styles.pills} !important;
          }

          ui-loading-component .spinner {
            border-left: 0.4rem solid ${template.styles.header} !important;
          }

          .button--primary {
            background-color: ${template.styles.button} !important;
          }
        `);
      }

      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      // eslint-disable-next-line import/no-deprecated
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styles));
      head.appendChild(style);
    }
  }

  setCssVars(): void {
    this.templateService.template$
      .pipe(
        first(),
        skipWhile(template => template === null)
      )
      .subscribe(template => {
        if (!template) {
          return;
        }
        const renderer2: Renderer2 = this.rendererFactory.createRenderer(
          null,
          null
        );
        let cssVars = '';
        for (const key in template.styles) {
          if (template.styles.hasOwnProperty(key) && key) {
            cssVars += `--${key}: ${template.styles[key]};`;
          }
        }
        renderer2.setAttribute(this.document.body, 'style', cssVars);
      });
  }
}
