import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { DEFAULT_REGION, RegionalApiService } from '@services';
import { globalRegions } from 'altru-types/@types';

@Injectable()
export class GlobalRegionGuard implements CanActivate {
  constructor(private regionalApiService: RegionalApiService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const globalRegionQueryParam: string =
      route.queryParams.global_region || '';
    const globalRegionRegExp = /^[a-zA-Z]{2}$/i;

    const globalRegion = globalRegionRegExp.test(globalRegionQueryParam)
      ? globalRegionQueryParam.toLowerCase()
      : DEFAULT_REGION;
    this.regionalApiService.setRegion(globalRegion as globalRegions);

    return true;
  }
}
