import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { LoadingComponent } from '../../components/loading/loading.component';

@Directive({
  selector: '[uiLoading]',
})
export class LoadingDirective implements OnChanges {
  @Input()
  uiLoading = false;

  private portalHost: DomPortalOutlet;
  private portal: ComponentPortal<LoadingComponent> = new ComponentPortal(
    LoadingComponent
  );
  private oldPositionStyle: string;

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.uiLoading.currentValue === true &&
      (changes.uiLoading.firstChange ||
        changes.uiLoading.previousValue === false)
    ) {
      this.addLoadingDiv();
    } else if (
      changes.uiLoading.currentValue === false &&
      changes.uiLoading.previousValue === true
    ) {
      this.removeLoadingDiv();
    }
  }

  private addLoadingDiv(): void {
    this.oldPositionStyle = this.elementRef.nativeElement.style.position;

    if (!this.oldPositionStyle || this.oldPositionStyle === 'static') {
      this.renderer2.setStyle(
        this.elementRef.nativeElement,
        'position',
        'relative'
      );
    }

    this.portalHost = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const portalRef: ComponentRef<LoadingComponent> = this.portalHost.attach(
      this.portal
    );
    const nativeElement: any = this.elementRef.nativeElement;
    portalRef.instance.size =
      Math.min(nativeElement.offsetHeight, nativeElement.offsetWidth) * 0.3;
    portalRef.changeDetectorRef.detectChanges();
  }

  private removeLoadingDiv(): void {
    if (this.portalHost.hasAttached()) {
      this.portalHost.detach();
      this.renderer2.setStyle(
        this.elementRef.nativeElement,
        'position',
        this.oldPositionStyle
      );
    }
  }
}
