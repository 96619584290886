import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'altru-video-volume',
  templateUrl: './video-volume.component.html',
  styleUrls: ['./video-volume.component.scss'],
})
export class VideoVolumeComponent {
  savedVolume = 1;

  @Input() volume = 1;
  @Input() minVideoSize = { minWidth: true, minHeight: true };

  @Output()
  changeVideoVolume: EventEmitter<number> = new EventEmitter<number>();

  changeVolume(volume: number): void {
    this.changeVideoVolume.emit(volume);
  }

  toggleMute(): void {
    if (this.volume === 0) {
      this.changeVolume(this.savedVolume || 1);
    } else {
      this.savedVolume = this.volume;
      this.changeVolume(0);
    }
  }
}
