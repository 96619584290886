import { ModuleWithProviders, NgModule } from '@angular/core';
import { MixpanelService } from './mixpanel.service';

@NgModule({
  imports: [],
})
export class MixpanelModule {
  static forRoot(): ModuleWithProviders<MixpanelModule> {
    return {
      ngModule: MixpanelModule,
      providers: [MixpanelService],
    };
  }
}
