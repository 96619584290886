import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import {
  AltruApiResponse,
  AltruApiSuccessfullResponse,
  Client,
  globalRegions,
} from 'altru-types/@types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const DEFAULT_REGION = 'us';

export function clientToRegion(client: Client): globalRegions {
  return client?.global_region ?? DEFAULT_REGION;
}

export function regionToApiUrl(region: string): string {
  return `${environment.apiDomain}/api/${region || DEFAULT_REGION}`;
}

export function regionToDistApiUrl(region: string): string {
  return `${environment.apiDomain}/dist-api/${region || DEFAULT_REGION}`;
}

@Injectable({
  providedIn: 'root',
})
export class RegionalApiService {
  private regionSubject$ = new BehaviorSubject<globalRegions>(DEFAULT_REGION);

  apiUrl$ = this.regionSubject$.pipe(map(regionToApiUrl));
  distApiUrl$ = this.regionSubject$.pipe(map(regionToDistApiUrl));

  setRegion(region: globalRegions): void {
    if (this.regionSubject$.value === region) {
      return;
    }

    this.regionSubject$.next(region);
  }

  setRegionFromClient(client: Client): void {
    this.setRegion(clientToRegion(client));
  }

  constructor(private httpClient: HttpClient) {}

  apiGet<T>(
    path: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<AltruApiResponse<T>> {
    return this.apiUrl$.pipe(
      switchMap((apiUrl: string) =>
        this.httpClient.get<AltruApiResponse<T>>(
          Location.joinWithSlash(apiUrl, path),
          options
        )
      )
    );
  }

  apiPut<T>(
    path: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<AltruApiResponse<T>> {
    return this.apiUrl$.pipe(
      switchMap((apiUrl: string) =>
        this.httpClient.put<AltruApiResponse<T>>(
          Location.joinWithSlash(apiUrl, path),
          body,
          options
        )
      )
    );
  }

  apiPatch<T>(
    path: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<AltruApiResponse<T>> {
    return this.apiUrl$.pipe(
      switchMap((apiUrl: string) =>
        this.httpClient.patch<AltruApiResponse<T>>(
          Location.joinWithSlash(apiUrl, path),
          body,
          options
        )
      )
    );
  }

  apiPost<T>(
    path: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<AltruApiResponse<T>> {
    return this.apiUrl$.pipe(
      switchMap((apiUrl: string) =>
        this.httpClient.post<AltruApiResponse<T>>(
          Location.joinWithSlash(apiUrl, path),
          body,
          options
        )
      )
    );
  }

  distApiGet<T>(
    path: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<AltruApiResponse<T>> {
    return this.distApiUrl$.pipe(
      switchMap((distApiUrl: string) =>
        this.httpClient.get<AltruApiResponse<T>>(
          Location.joinWithSlash(distApiUrl, path),
          options
        )
      )
    );
  }
}

export const successfulResponse = <T>(
  r: AltruApiResponse<T>
): r is AltruApiSuccessfullResponse<T> => r.data !== undefined;

export const filterSuccessfulResponse = <T>(
  res: AltruApiResponse<T>
): AltruApiSuccessfullResponse<T> => {
  if (!successfulResponse(res)) {
    throw new Error(res.message || 'Unknown error');
  } else {
    return res;
  }
};
