import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AltruApiResponse, PostVerifyBody, Template } from 'altru-types';
import * as TemplateActions from '@store/template/template.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StoreShape } from '@store/action-reducers';
import { RegionalApiService } from '@services';

export interface StyleOverrides {
  primary_color?: string;
  secondary_color?: string;
}

@Injectable()
export class TemplateService {
  constructor(
    private regionalApiService: RegionalApiService,
    private store: Store<StoreShape>
  ) {}

  get template$(): Observable<Template | null> {
    return this.store.select('template');
  }

  getTemplate(
    slug: string,
    template: string,
    overrides: StyleOverrides = {},
    cachebust: string | number | null = null
  ): Observable<AltruApiResponse<Template>> {
    return this.regionalApiService
      .distApiGet<Template>(`templates/${slug}/${template}`, {
        ...(cachebust && { params: { cachebust: `${cachebust}` } }),
      })
      .pipe(
        tap(res => {
          if (res.data) {
            this.storeTemplate(res.data, overrides);
          }
        })
      );
  }

  verify(
    templateId: number,
    body: PostVerifyBody
  ): Observable<AltruApiResponse<void>> {
    return this.regionalApiService.apiPost<void>(
      `templates/${templateId}/verify`,
      body
    );
  }

  storeTemplate(template: Template, overrides: StyleOverrides = {}): void {
    this.store.dispatch(
      new TemplateActions.Set(this.mergeOverrides(template, overrides))
    );
  }

  mergeOverrides(templateData: Template, overrides: StyleOverrides): Template {
    return {
      ...templateData,
      styles: {
        ...templateData.styles,
        ...(overrides.primary_color && {
          text: overrides.primary_color,
          header: overrides.primary_color,
          'text-alert': overrides.primary_color,
          'question-title': overrides.primary_color,
          pills: overrides.primary_color,
        }),
        ...(overrides.secondary_color && {
          button: overrides.secondary_color,
          'pills-secondary': overrides.secondary_color,
        }),
      },
    };
  }
}
