import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import * as AnswersActions from '@store/answers/answers.actions';

import { AltruApiResponse, Answer, GetAnswersQueryParams } from 'altru-types';

import { forkJoin, Observable } from 'rxjs';
import { StoreShape } from '@store/action-reducers';
import { RegionalApiService } from '@services';

@Injectable()
export class AnswerService {
  constructor(
    private regionalApiService: RegionalApiService,
    private store: Store<StoreShape>
  ) {}

  get answers$(): Observable<Answer[]> {
    return this.store.select('answers');
  }

  storeAnswers(answers: Answer[]): void {
    this.store.dispatch(
      new AnswersActions.Set(answers.map(_answer => _answer))
    );
  }

  storeAnswer(answer: Answer): void {
    this.store.dispatch(new AnswersActions.ReplaceAnswer(answer));
  }

  getAnswers(
    queryParams: GetAnswersQueryParams,
    isPublished: boolean
  ): Observable<AltruApiResponse<Answer[]>> {
    let params: HttpParams = new HttpParams();

    // To support CloudFront invalidation we always set the template_id query param first
    if (queryParams.hasOwnProperty('template_id') && queryParams.template_id) {
      params = params.append('template_id', `${queryParams.template_id}`);
    }

    for (const param in queryParams) {
      if (
        param !== 'template_id' &&
        queryParams.hasOwnProperty(param) &&
        queryParams[param]
      ) {
        params = params.append(param, queryParams[param]);
      }
    }

    const answersResp$ = isPublished
      ? this.regionalApiService.distApiGet<Answer[]>('answers', { params })
      : this.regionalApiService.apiGet<Answer[]>('answers', { params });

    return answersResp$.pipe(
      tap(res => {
        const answersObjects: Answer[] = [];
        for (const answer of res.data || []) {
          answersObjects.push({
            ...answer,
            has_liked: answer.has_liked || false,
          });
        }

        this.storeAnswers(answersObjects);
      })
    );
  }

  getAnswer(answerId: number): Observable<AltruApiResponse<Answer>> {
    return this.regionalApiService
      .distApiGet<Answer>(`answers/${answerId}`)
      .pipe(
        tap(res => {
          const answerObject: Answer | undefined = res.data;
          if (answerObject) {
            this.storeAnswer(answerObject);
          }
        })
      );
  }

  likeAnswer(answerId: number): any {
    const httpRequest$ = this.regionalApiService
      .apiPatch<void>(`answers/${answerId}/like`, {})
      .pipe(take(1));

    const answer$ = this.answers$.pipe(
      take(1),
      map(answers => answers.find(a => a.id === answerId))
    );

    const updateStore = ({ answer }: { answer: Answer | undefined }) => {
      if (answer) {
        this.storeAnswer({
          ...answer,
          ...{
            has_liked: true,
            likes: (answer.likes ?? 0) + 1,
          },
        });
      }
    };

    forkJoin({
      answer: answer$,
      resp: httpRequest$,
    }).subscribe({
      next: updateStore,
    });
  }
}
