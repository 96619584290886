import { Environment } from '../app/models/environment.model';

export const environment: Environment = {
  production: false,
  mock: false,
  devTools: true,
  sentry: false,
  env: 'staging',
  apiDomain: 'https://api-staging.altrulabs.com',
  baseUrl: 'https://videos-staging.talentplatform.com',
  trackers: ['mixpanel'],
  snowplowUrl: 'https://analytics.jibecdn.com/prod/jibe.analytics.js',
};
