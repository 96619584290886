import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  size = 40;

  constructor() {}
}
