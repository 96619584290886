import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { HelperService } from 'src/app/services';
import { EMPTY_CARD, UiCard } from '@shared/components';

@Component({
  selector: 'ui-card-thumbnail',
  templateUrl: './card-thumbnail.component.html',
  styleUrls: ['./card-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardThumbnailComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private helperService: HelperService
  ) {}

  private mouseOverSubject$ = new BehaviorSubject<boolean>(false);

  mouseOver$ = this.mouseOverSubject$.pipe(distinctUntilChanged());
  loadingImg = true;

  @Input()
  card: UiCard = EMPTY_CARD;

  imgSrc = imgSrc;

  onMouseEnter(): void {
    if (!this.mouseOverSubject$.value) {
      this.mouseOverSubject$.next(true);
    }
  }

  onMouseLeave(): void {
    this.mouseOverSubject$.next(false);
  }

  onImageLoad(): void {
    this.loadingImg = false;
  }

  nativeLazySupport(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return true;
    }
    const browser = this.helperService.getBrowserName();
    return browser !== 'Safari' && browser !== 'IE';
  }
}

const imgSrc = (
  card: UiCard,
  videoThumbnail: HTMLDivElement,
  mouseOver: boolean
): string => {
  const cardWidth = videoThumbnail.parentElement
    ? 10 * Math.ceil(videoThumbnail.parentElement.clientWidth / 10)
    : 0;

  const cardHeight = Math.ceil(
    cardWidth * (card.orientation === 'portrait' ? 1.33 : 0.75)
  );

  const safeSrc = mouseOver
    ? card.gifUrl || card.thumbnailUrl
    : card.thumbnailUrl;

  if (!safeSrc) {
    return '';
  }

  const url = new URL(safeSrc);
  url.searchParams.append('width', `${cardWidth}`);
  url.searchParams.append('height', `${cardHeight}`);

  return url.toString();
};
