import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { User } from 'altru-types';

type size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

@Component({
  selector: 'altru-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @ViewChild('avatarContainer', { static: true })
  private avatarContainer: ElementRef;

  @Input()
  size: size = 'sm';

  @Input()
  set user(value: User) {
    this.firstName = value.first_name;
    this.imageUrl = value.image_url;
  }

  @Input()
  firstName = '';

  @Input()
  imageUrl = '';

  @Input()
  imgAlt: string;

  constructor() {}

  ngOnInit(): void {
    this.avatarContainer.nativeElement.classList.add(`avatar--${this.size}`);
  }

  get imageSrc(): string {
    return this.imageUrl ? `${this.imageUrl}?width=60&height=60` : '';
  }

  get initials(): string {
    return toInitials(this.firstName);
  }
}

const toInitials = (firstName: string): string => {
  if (firstName) {
    const firstInitial = firstName[0];
    const secondInitial = firstName[1];
    return secondInitial ? `${firstInitial}${secondInitial}` : firstInitial;
  }

  return '';
};
