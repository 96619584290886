import { NgModule } from '@angular/core';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faSearch,
  faTimes,
  faSortUp,
  faSortDown,
  faExpand,
  faCompress,
  faHashtag,
  faHeart as fasHeart,
  faSlidersH,
  faFileAlt,
  faAngleDown,
  faAngleUp,
  faCheck,
  faAngleRight,
  faUserSecret,
  faVideoSlash,
  faFilter,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTimesCircle,
  faPlayCircle,
  faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebookF);
    library.addIcons(faLinkedinIn);
    library.addIcons(faTwitter);
    library.addIcons(faSearch);
    library.addIcons(faTimes);
    library.addIcons(faTimesCircle);
    library.addIcons(faSortUp);
    library.addIcons(faSortDown);
    library.addIcons(faExpand);
    library.addIcons(faCompress);
    library.addIcons(faPlayCircle);
    library.addIcons(faHashtag);
    library.addIcons(farHeart);
    library.addIcons(fasHeart);
    library.addIcons(faSlidersH);
    library.addIcons(faFileAlt);
    library.addIcons(faAngleDown);
    library.addIcons(faAngleUp);
    library.addIcons(faCheck);
    library.addIcons(faAngleRight);
    library.addIcons(faUserSecret);
    library.addIcons(faVideoSlash);
    library.addIcons(faFilter);
    library.addIcons(faLink);
  }
}
