import * as R from 'ramda';
import * as MeActions from './me.actions';

import { User } from 'altru-types';

type Action = MeActions.All;

export const INITIAL_STATE: User | null = null;

export function meReducer(
  state: User | null = INITIAL_STATE,
  action: Action
): User | null {
  switch (action.type) {
    case MeActions.SET:
      return R.clone(action.payload as User);
    default:
      return state;
  }
}
