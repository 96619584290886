/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { Answer } from 'altru-types';

export const SET = '[Answers] Set';
export const REPLACE_ANSWER = '[Answers] Replace answer';
export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: Answer[]) {}
}
export class ReplaceAnswer implements Action {
  readonly type: string = REPLACE_ANSWER;
  constructor(public payload: Answer) {}
}

export type All = Set | ReplaceAnswer;
