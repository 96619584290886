import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  NgZone,
  Output,
  Inject,
  PLATFORM_ID,
  Input,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[altruResizeObserver]',
})
export class ResizeObserverDirective implements OnInit {
  @Input()
  isIEOrEdge: boolean;

  @Output()
  sizeChange: EventEmitter<DOMRect> = new EventEmitter<DOMRect>();

  // @ts-ignore typescript doesn't know about this yet
  resizeObserver: ResizeObserver;
  resizeUnsubscriber: () => void = () => {};

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      (async () => {
        if ('ResizeObserver' in window === false) {
          const module = await import(
            /* webpackChunkName: "resize-observer" */ '@juggle/resize-observer'
          );
          // @ts-ignore typescript doesn't know about this yet
          window.ResizeObserver = module.ResizeObserver;
        }

        // @ts-ignore typescript doesn't know about this yet
        const resizeObserver = new ResizeObserver(([{ contentRect }]) => {
          this.ngZone.run(() => {
            this.sizeChange.emit(contentRect);
          });
        });
        resizeObserver.observe(this.element.nativeElement);
        this.resizeUnsubscriber();
        this.resizeUnsubscriber = () => {
          resizeObserver.unobserve(this.element.nativeElement);
        };
      })();
    }
  }
}
