import * as WidgetActions from './widget.actions';

type Action = WidgetActions.All;

export const INITIAL_STATE: number | null = null;

export function widgetReducer(
  state: number | null = INITIAL_STATE,
  action: Action
): number | null {
  switch (action.type) {
    case WidgetActions.SET:
      return action.payload;
    default:
      return state;
  }
}
