import { ModuleWithProviders, NgModule } from '@angular/core';
import { WidgetMessageService } from './widget-message.service';

@NgModule({})
export class WidgetMessageModule {
  static forRoot(): ModuleWithProviders<WidgetMessageModule> {
    return {
      ngModule: WidgetMessageModule,
      providers: [WidgetMessageService],
    };
  }
}
