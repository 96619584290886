import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Client } from 'altru-types';
import { ClientService } from '@services';

@Injectable()
export class ClientGuardService implements CanActivateChild {
  constructor(private router: Router, private clientService: ClientService) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    const clientSlug: string = route.params.client_slug;
    const storedClient$ = this.clientService.client$.pipe(first());

    return storedClient$.pipe(
      switchMap(storedClient =>
        storedClient && storedClient.slug === clientSlug
          ? of(true)
          : this.clientService.getClient(clientSlug).pipe(
              map(clientRes => {
                const client: Client | undefined = clientRes.data;
                if (client && client.slug === clientSlug) {
                  return true;
                } else {
                  this.router.navigate(['/not-found']);
                  return false;
                }
              }),
              catchError(_ => {
                this.router.navigate(['/not-found']);
                return of(false);
              })
            )
      )
    );
  }
}
