import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentEntryDirective } from '@shared/directives/component-entry/component-entry.directive';

import { Store } from '@ngrx/store';
import * as UIActions from '@store/ui/ui.actions';
import { StoreShape } from '@store/action-reducers';

@Component({
  selector: 'altru-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy {
  @ViewChild(ComponentEntryDirective, { static: true })
  componentEntry: ComponentEntryDirective;

  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;

  @Output()
  closed: EventEmitter<Event> = new EventEmitter<Event>();

  isCloseButtonVisible = true;
  closeOnBackdropClick = true;
  opened = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<StoreShape>
  ) {
    this.store.dispatch(UIActions.modifyDialogState({ visibility: true }));
  }

  ngOnDestroy(): void {
    this.document.body.style.overflowY = 'auto';
    this.store.dispatch(UIActions.modifyDialogState({ visibility: false }));
  }

  open(
    isCloseButtonVisible = true,
    closeOnBackdropClick = true,
    $event?: Event
  ): void {
    this.isCloseButtonVisible = isCloseButtonVisible;
    this.closeOnBackdropClick = closeOnBackdropClick;
    this.opened = true;
    this.changeDetector.detectChanges();
    this.document.body.style.overflowY = 'hidden';
    if (this.isCloseButtonVisible) {
      this.closeButton.nativeElement.focus();
    }
  }

  close($event?: MouseEvent): void {
    if (
      !$event ||
      ($event.target as HTMLDivElement).classList.contains('modal-backdrop')
    ) {
      this.closed.emit($event);
      this.opened = false;
    }
  }

  onBackdropClick($event: MouseEvent): void {
    if (
      ($event.target as HTMLDivElement).classList.contains('modal-backdrop') &&
      this.closeOnBackdropClick
    ) {
      this.close($event);
    }
  }

  onCloseButtonClick($event: MouseEvent): void {
    this.closed.emit($event);
    this.opened = false;
  }

  loadComponent(component: Type<any>): ComponentRef<any> {
    const componentEntryRef: ViewContainerRef = this.componentEntry
      .viewContainerRef;
    const componentFactory: ComponentFactory<Component> = this.componentFactoryResolver.resolveComponentFactory(
      component
    );

    componentEntryRef.clear();
    return componentEntryRef.createComponent(componentFactory);
  }

  clear(): void {
    this.componentEntry.viewContainerRef.clear();
  }
}
