import * as R from 'ramda';

import * as ApiLangActions from './api-lang.actions';
import { AvailableLang } from './api-lang.actions';

type Action = ApiLangActions.All;

export const INITIAL_STATE: AvailableLang = 'en';

export function apiLangReducer(
  state: AvailableLang = INITIAL_STATE,
  action: Action
): AvailableLang {
  switch (action.type) {
    case ApiLangActions.SET:
      return R.clone(action.payload as AvailableLang);
    default:
      return state;
  }
}
