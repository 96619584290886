import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'altru-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input()
  value: number;

  @Input()
  max: number;

  @Input()
  label: string;

  @Output()
  changeValue: EventEmitter<number> = new EventEmitter<number>();
}
