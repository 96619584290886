import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ClientService, TemplateService } from '@services';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@environments';
import { Environment } from 'src/app/models/environment.model';
import { MixpanelService } from './mixpanel/mixpanel.service';
import { WidgetType } from 'altru-types/@types';
import { ConsoleService } from './console/console.service';
import { SnowplowService } from './snowplow/snowplow.service';

export enum TrackEvents {
  EMBED_PLAYER_LOADED = 'Embed Player Loaded',
  VIDEO_PLAYER_LOADED = 'Video Player Loaded',
  ANSWER_PLAYER_LOADED = 'Answer Player Loaded',
  FEED_LOADED = 'Feed Loaded',
  CTA_CLICKED = 'Cta Clicked',
  SEARCH_RESULT_CLICKED = 'Search Result Clicked',
  TAG_CLICKED = 'Tag Clicked',
  SHOW_VIDEO = 'showVideo',
  VIDEO_STOPPED = 'Video Stopped',
}

export type ApplicantStatus = {
  clientId: number;
  widgetId?: string;
  widgetType?: WidgetType | 'feed';
  templateId?: number;
};

export type TrackEvent = keyof typeof TrackEvents;

export interface ITrackService {
  track(action: TrackEvent, status: ApplicantStatus, properties?: object): void;
}

// i can't figure out how to tell typescript that MixpanelService constructs an ITrackService
export const serviceMap: Record<Environment['trackers'][number], any> = {
  mixpanel: MixpanelService,
  console: ConsoleService,
  snowplow: SnowplowService,
};
@Injectable()
export class TrackService {
  trackers: ITrackService[] = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private injector: Injector,
    private clientService: ClientService,
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute
  ) {
    const wantedTrackers = new Set<string>([
      ...environment.trackers,
      ...this.ondemandTrackers(),
    ]);
    this.trackers = Array.from(wantedTrackers)
      .map(tracker => serviceMap[tracker])
      .filter(Boolean)
      .map(this.injector.get.bind(this.injector));
  }

  ondemandTrackers(): string[] {
    const trackers: string[] = [];
    if ('undefined' === typeof document) {
      return trackers;
    }

    if (/[?&]verbose=true(&|$)/.test(document.location.search)) {
      trackers.push('console');
    }
    const matches = document.location.search.match(/trackers=(\w+,)*\w+/);
    if (matches && matches[0]) {
      const matchArray = matches[0].match(/[^=]*$/);
      if (matchArray) {
        trackers.push(...matchArray[0].split(','));
      }
    }

    return trackers;
  }

  track(action: TrackEvent, properties?: object): void {
    // do not track the server!
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    combineLatest([this.clientService.client$, this.templateService.template$])
      .pipe(take(1))
      .subscribe({
        next: ([client, template]) => {
          if (!client) {
            return;
          }
          const widgetId = this.activatedRoute.snapshot.queryParams.widgetId;
          const status: ApplicantStatus = {
            clientId: client.id,
            templateId: template?.id,
            widgetId: widgetId || undefined,
            widgetType: widgetId ? 'feed' : undefined,
          };

          for (const tracker of this.trackers) {
            tracker.track(action, status, properties);
          }
        },
      });
  }
}
