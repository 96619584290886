import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  HostBinding,
} from '@angular/core';

type Nullable<T> = T | null;

@Directive({
  selector: '[uiBackground]',
})
export class BackgroundDirective implements OnInit {
  @Input('uiBackground') background: string;

  @HostBinding('style.color')
  color: string;

  @HostBinding('style.background-color')
  backgroundColor = '#FFFFFF';

  private element: ElementRef;

  constructor(element: ElementRef) {
    this.element = element;
  }

  ngOnInit(): void {
    if (this.background) {
      this.backgroundColor = this.background;
      this.color =
        this.contrast(
          [255, 255, 255],
          this.hexToRgb(this.background) ?? [0, 0, 0]
        ) > 4.5
          ? '#FFFFFF'
          : '#000000';
    }

    const classes: string[] = [];
    classes.forEach(_class => {
      this.element.nativeElement.classList.add(_class);
    });
  }

  private hexToRgb(hex: string): Nullable<number[]> {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
  }

  private luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(v => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  private contrast(rgb1: number[], rgb2: number[]): number {
    return (
      (this.luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05) /
      (this.luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05)
    );
  }
}
