import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  Type,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ModalComponent } from '../../shared/components';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ModalService implements OnDestroy {
  dialogOpened = false;

  private modalComponentRef: ComponentRef<ModalComponent>;
  private componentDestroyed: Subject<any> = new Subject();
  private renderer: Renderer2;

  get modal(): ModalComponent | null {
    return this.modalComponentRef ? this.modalComponentRef.instance : null;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    // TODO: Use new dialog status on the UI
    // this.store.select('dialog').subscribe(opened => {
    //   this.dialogOpened = opened;
    // });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  open(isCloseButtonVisible = true, closeOnBackdropClick = true): void {
    if (this.modal) {
      this.modal.open(isCloseButtonVisible, closeOnBackdropClick);
    }
  }

  close(): void {
    if (this.modal) {
      this.modal.close();
      this.clear();
    }
  }

  loadComponent(component: Type<any>): ComponentRef<any> {
    if (this.modalComponentRef) {
      this.clear();
    }

    this.modalComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(ModalComponent)
      .create(this.injector);
    this.appRef.attachView(this.modalComponentRef.hostView);
    const domElem: HTMLElement = (this.modalComponentRef
      .hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.renderer.appendChild(this.document.body, domElem);
    this.modalComponentRef.instance.closed
      .asObservable()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => this.clear());

    return this.modalComponentRef.instance.loadComponent(component);
  }

  clear(): void {
    if (this.modal) {
      this.modal.clear();
      this.modalComponentRef.hostView.destroy();
      this.appRef.detachView(this.modalComponentRef.hostView);
      this.modalComponentRef.destroy();
    }
  }
}
