import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { LoaderComponent } from '@shared/components';

@Injectable()
export class LoadingService {
  private loaderComponentRef: ComponentRef<LoaderComponent> | null = null;
  private requestsLoading = 0;
  private renderer: Renderer2;
  private isLoading = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  isVisible(): boolean {
    return this.isLoading;
  }

  show(): void {
    this.isLoading = true;
    this.requestsLoading++;
    if (this.requestsLoading > 1) {
      return;
    }

    this.loaderComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(LoaderComponent)
      .create(this.injector);
    this.appRef.attachView(this.loaderComponentRef.hostView);
    const domElem: HTMLElement = (this.loaderComponentRef
      .hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.renderer.appendChild(this.document.body, domElem);
  }

  hide(): void {
    this.isLoading = false;
    if (this.requestsLoading > 0) {
      this.requestsLoading--;
    }
    if (this.requestsLoading === 0 && this.loaderComponentRef) {
      this.appRef.detachView(this.loaderComponentRef.hostView);
      this.loaderComponentRef.destroy();
      this.loaderComponentRef = null;
    }
  }
}
