import { ActionReducerMap } from '@ngrx/store';

import { AvailableLang } from './api-lang/api-lang.actions';

import { Answer, Client, Template, Video } from 'altru-types';
import { State as UIState } from './ui/ui.reducer';

import { answersReducer } from './answers/answers.reducer';
import { apiLangReducer } from './api-lang/api-lang.reducer';
import { clientReducer } from './client/client.reducer';
import { identityReducer } from './identity/identity.reducer';
import { meReducer } from './me/me.reducer';
import { templateReducer } from './template/template.reducer';
import { videosReducer } from './videos/videos.reducer';
import { widgetReducer } from './widget/widget.reducer';
import { uiReducer } from './ui/ui.reducer';

import { Identity, User } from 'altru-types';

export type StoreShape = {
  answers: Answer[];
  apiLang: AvailableLang;
  me: User | null;
  client: Client | null;
  template: Template | null;
  identity: Identity | null;
  videos: Video[];
  widget: number | null;
  ui: UIState;
};

export const reducers: ActionReducerMap<StoreShape> = {
  answers: answersReducer,
  apiLang: apiLangReducer,
  me: meReducer,
  client: clientReducer,
  template: templateReducer,
  videos: videosReducer,
  identity: identityReducer,
  widget: widgetReducer,
  ui: uiReducer,
};
