import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
})
export class InputContainerComponent {
  @Input()
  preIcon: string;

  @Input()
  postIcon: string;
}
