import { Injectable } from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import {
  AltruApiResponse,
  Answer,
  answerStatus,
  Template,
  Video,
} from 'altru-types';
import {
  AnswerService,
  TemplateService,
  HelperService,
  VideoService,
  TEMPLATE_RESPONSE_KEY,
  TemplateResolverService,
} from '@services';

const DEFAULT_ANSWER_STATUSES: answerStatus[] = ['approved'];
const FEED_SECTION_ANSWER_RESPONSES_KEY: StateKey<AltruApiResponse<
  Answer[]
>> = makeStateKey('feed-section-responses');
const FEED_SECTION_VIDEO_RESPONSES_KEY: StateKey<AltruApiResponse<
  Video[]
>> = makeStateKey('feed-section-video-responses');

@Injectable()
export class FeedSectionResolverService
  implements
    Resolve<
      | Record<string, never>
      | {
          answers: AltruApiResponse<Answer[]>;
          videos: AltruApiResponse<Video[]>;
        }
    > {
  constructor(
    private templateResolverService: TemplateResolverService,
    private transferState: TransferState,
    private answerService: AnswerService,
    private videoService: VideoService,
    private templateService: TemplateService,
    private router: Router,
    private helperService: HelperService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<
    | Record<string, never>
    | {
        answers: AltruApiResponse<Answer[]>;
        videos: AltruApiResponse<Video[]>;
      }
  > {
    const storedAnswers: AltruApiResponse<
      Answer[]
    > | null = this.transferState.get<AltruApiResponse<Answer[]> | null>(
      FEED_SECTION_ANSWER_RESPONSES_KEY,
      null
    );
    const storedVideos: AltruApiResponse<
      Video[]
    > | null = this.transferState.get<AltruApiResponse<Video[]> | null>(
      FEED_SECTION_VIDEO_RESPONSES_KEY,
      null
    );
    const storedTemplate: AltruApiResponse<
      Template
    > | null = this.transferState.get<AltruApiResponse<Template> | null>(
      TEMPLATE_RESPONSE_KEY,
      null
    );

    if (storedAnswers && storedVideos && storedTemplate) {
      if (storedTemplate.data) {
        this.templateService.storeTemplate(
          storedTemplate.data,
          route.queryParams
        );
      }
      this.answerService.storeAnswers(storedAnswers.data || []);
      this.videoService.storeVideos(storedVideos.data || []);
      if (storedTemplate.data) {
        this.makeTheme(storedTemplate.data);
      }
      return of({
        answers: storedAnswers,
        videos: storedVideos,
      });
    }

    return this.templateResolverService.resolve(route).pipe(
      map(({ data }) => data),
      switchMap(template => {
        if (!template) {
          return forkJoin({
            answers: [],
            videos: [],
          });
        }
        this.makeTheme(template);
        return forkJoin({
          answers: this.answerService
            .getAnswers(
              {
                template_id: template.id,
                statuses: DEFAULT_ANSWER_STATUSES.join(','),
                order_by: 'recorded_at',
                per_page: 500,
              },
              template.is_published || false
            )
            .pipe(take(1)),
          videos: this.videoService
            .getTemplateVideos(
              {
                orderBy: 'created_at',
                perPage: 500,
              },
              template.id,
              template.client_id,
              template.is_published
            )
            .pipe(take(1)),
        });
      }),
      tap(({ answers, videos }) => {
        this.transferState.set<AltruApiResponse<Answer[]>>(
          FEED_SECTION_ANSWER_RESPONSES_KEY,
          answers
        );
        this.transferState.set<AltruApiResponse<Video[]>>(
          FEED_SECTION_VIDEO_RESPONSES_KEY,
          videos
        );
      }),
      catchError(_e => {
        this.router.navigate(['/not-found']);
        return of({});
      }),
      take(1)
    );
  }

  // TODO: is this still needed?
  makeTheme(templateData: Template): void {
    const css = `
      altru-password-modal .submit-button {
        background-color: ${templateData.styles.button} !important;
      }

      ui-card .title {
        color: ${templateData.styles['question-title']} !important;
      }

      ui-card-details .user-name {
        color: ${templateData.styles['text-alert']} !important;
      }
      ui-card-details .job-title {
        color: ${templateData.styles.info} !important;
      }

      ui-card-details .social fa-icon {
        color: ${templateData.styles.social} !important;
      }

      ui-card-details .social share-button fa-icon  {
        background-color: ${templateData.styles.social} !important;
      }

      ui-card-details .tags .pill {
        background-color: ${templateData.styles.pills} !important;
      }

      ui-card-details .tags .pill.secondary {
        background-color: ${templateData.styles['pills-secondary']} !important;
      }

      altru-detail-view .info-text .answered-by {
        color: ${templateData.styles.text} !important;
      }

      altru-detail-view .info-text .user-name {
        color: ${templateData.styles['text-alert']} !important;
      }

      altru-detail-view .info-text .job-title {
        color: ${templateData.styles.info} !important;
      }

      altru-detail-view .cta-button {
        background-color: ${templateData.styles.button} !important;
      }

      altru-detail-view .pill {
        background-color: ${templateData.styles.pills} !important;
      }

      altru-detail-view .tags-container .pill {
        background-color: ${templateData.styles.pills} !important;
      }

      altru-detail-view .tags-container .pill.secondary {
        background-color: ${templateData.styles['pills-secondary']} !important;
      }
      altru-detail-view .similar-answers-title {
        color: ${templateData.styles.text} !important;
      }

      altru-detail-view .similar-answers h3 {
        color: ${templateData.styles['question-title']} !important;
      }

      altru-feed-section {
        color: ${templateData.styles.text} !important;
      }

      altru-feed-section > .header {
        background-color: ${templateData.styles.header} !important;
      }

      altru-feed .cards-section .title {
        color: ${templateData.styles.title} !important;
      }

      altru-feed .cards-section .count {
        color: ${templateData.styles.info} !important;
      }

      altru-feed button.fav {
        background-color: ${templateData.styles.button} !important;
      }

      altru-filters .header fa-icon {
        color: ${templateData.styles.pills} !important;
      }

      altru-filters order-by ui-select {
        color: ${templateData.styles['text-alert']} !important;
      }

      altru-filters order-by ui-select .opened .option {
        color: ${templateData.styles['text-alert']} !important;
      }

      altru-filters .filter-group .filter.selected .tag-title {
        color: ${templateData.styles['text-alert']} !important;
      }

      altru-header {
        background-color: ${templateData.styles.header} !important;
      }

      altru-header .client-logo ui-search-input {
        background-color: ${templateData.styles['search-bar']} !important;
      }

      ui-search-input i,
      ui-search-input input {
        color: ${templateData.styles['search-bar-text']} !important;
      }

      altru-feed .bt-toggle-sidebar {
        background-color: ${templateData.styles.button};
      }
    `;

    this.helperService.setIEStyles(css, templateData);
  }
}
