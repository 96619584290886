import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDetailsComponent } from './card-details/card-details.component';
import { CardSocialButtonsComponent } from './card-social-buttons/card-social-buttons.component';
import { CardThumbnailComponent } from './card-thumbnail/card-thumbnail.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardComponent } from './card.component';
import { TranslateModule } from '@ngx-translate/core';
import { DurationPipeModule } from '@shared/pipes/duration/duration-pipe.module';
import { IconsModule } from '@shared/modules/icons.module';
import { AvatarModule } from '@shared/components/avatar/avatar.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoadingDirectiveModule } from '@shared/directives/loading/loading-directive.module';
import { BackgroundDirectiveModule } from '@shared/directives/background/background-directive.module';
import { HighlightPipeModule } from '@shared/pipes/highlight-pipe/highlight-pipe.module';

@NgModule({
  declarations: [
    CardDetailsComponent,
    CardSocialButtonsComponent,
    CardThumbnailComponent,
    CardTitleComponent,
    CardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DurationPipeModule,
    IconsModule,
    AvatarModule,
    LazyLoadImageModule,
    LoadingDirectiveModule,
    BackgroundDirectiveModule,
    HighlightPipeModule,
  ],
  exports: [
    CardDetailsComponent,
    CardSocialButtonsComponent,
    CardThumbnailComponent,
    CardTitleComponent,
    CardComponent,
  ],
})
export class CardModule {}
