import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SnackbarPayload {
  message: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  toasts: SnackbarPayload[] = [];
  delay = 2500;

  subject = new BehaviorSubject<SnackbarPayload[] | null>(null);
  toasts$ = this.subject.asObservable();

  show(toast: SnackbarPayload) {
    this.toasts = [toast, ...this.toasts];
    this.subject.next(this.toasts);

    setTimeout(() => {
      this.toasts = this.toasts.filter(v => v !== toast);
      this.subject.next(this.toasts);
    }, toast.delay ?? this.delay);
  }

  hide(index: number) {
    this.toasts = this.toasts.filter((toast, i) => i !== index);
    this.subject.next(this.toasts);
  }
}
