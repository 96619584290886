import { Pipe, PipeTransform } from '@angular/core';
import { UiCard } from '@shared/components';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(items: any[], order: string): any {
    switch (order) {
      case 'newest':
        return [
          ...items.sort(
            (a: UiCard, b: UiCard) => Date.parse(b.date) - Date.parse(a.date)
          ),
        ];
      case 'oldest':
        return [
          ...items.sort(
            (a: UiCard, b: UiCard) => Date.parse(a.date) - Date.parse(b.date)
          ),
        ];
    }

    // Fallback: Assume order = 'random'
    for (let i: number = items.length - 1; i > 0; i--) {
      const j: number = Math.floor(Math.random() * (i + 1));
      [items[i], items[j]] = [items[j], items[i]];
    }

    return [...items];
  }
}
