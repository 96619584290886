import { Identity } from 'altru-types';
import * as R from 'ramda';
import * as IdentityActions from './identity.actions';

type Action = IdentityActions.All;

export const INITIAL_STATE: Identity | null = null;

export function identityReducer(
  state: Identity | null = INITIAL_STATE,
  action: Action
): Identity | null {
  switch (action.type) {
    case IdentityActions.SET:
      return R.clone(action.payload as Identity);
    default:
      return state;
  }
}
