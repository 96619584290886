import { Injectable } from '@angular/core';

import {
  ApplicantStatus,
  ITrackService,
  TrackEvent,
  TrackEvents,
} from '@modules/track/track.service';

@Injectable()
export class ConsoleService implements ITrackService {
  constructor() {}

  track(
    action: TrackEvent,
    status: ApplicantStatus,
    properties?: object
  ): void {
    console.log(TrackEvents[action], status, properties);
  }
}
