import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IErrors } from '..';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent {
  @Input()
  inputId: string;

  @Input()
  tooltip: string;

  @Input()
  label = '';

  @Input()
  inputFormControl: UntypedFormControl;

  @Input()
  errors: IErrors;

  @Input()
  tabbable: boolean;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @HostBinding('class.checked')
  @Input()
  value = false;

  @HostBinding('class.inline')
  @Input()
  inline = false;

  @Output()
  alterChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange: (value: boolean) => void = (value: boolean) => {};
  onTouched: () => void = () => {};

  writeValue(value: boolean): void {
    if (value !== null) {
      this.value = value;
      this.onChange(value);
      this.alterChecked.emit(value);
    }
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  alterValue(): void {
    if (!this.disabled) {
      this.writeValue(!this.value);
    }
  }
}
