/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';

export const SET = '[Widget] Set';

export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: number) {}
}

export type All = Set;
