import { HttpClient } from '@angular/common/http';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export const translateFactory = (
  platformId: any,
  http: HttpClient
): TranslateHttpLoader => new TranslateHttpLoader(http);

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateFactory,
        deps: [PLATFORM_ID, HttpClient],
      },
    }),
  ],
})
export class TranslationsModule {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {}
}
