import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const AUTH_KEY = 'auth-key';
const STORAGE_ERROR_MESSAGE =
  "Sorry, we can't access to your browser storage so you must login on each session as we can't save it. \
Please, check your browser cookie settings.";

interface IAuthHeaders {
  uid: string;
  client: string;
  'access-token': string;
}

@Injectable()
export class StorageService {
  private appStorage: { [key: string]: string } = {};
  private availableLocalStorage =
    isPlatformBrowser(this.platformId) && navigator.cookieEnabled;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  set authHeaders(authValues: IAuthHeaders | null) {
    this.setLocal('auth', JSON.stringify(authValues));
  }

  get authHeaders(): IAuthHeaders | null {
    return this.getLocal('auth')
      ? JSON.parse(this.getLocal('auth') ?? 'null')
      : null;
  }

  set token(token: string | null) {
    this.setLocal('token', token ?? '');
  }

  get token(): string | null {
    return this.getLocal('token');
  }

  set templatePassword(auth: { templateId: number; password: string } | null) {
    if (!auth) {
      return;
    }
    const cypheredAuth = JSON.stringify({
      ...auth,
      password: btoa(auth.password),
    });
    this.setLocal(AUTH_KEY, cypheredAuth);
    this.setSession(AUTH_KEY, cypheredAuth);
  }

  get templatePassword(): { templateId: number; password: string } | null {
    try {
      const cypheredAuthString =
        this.getLocal(AUTH_KEY) || this.getSession(AUTH_KEY);
      const cypheredAuth = JSON.parse(cypheredAuthString as string);
      return { ...cypheredAuth, password: atob(cypheredAuth.password) };
    } catch {
      return null;
    }
  }

  set templatePasswordSession(auth: { templateId: number; password: string }) {
    const cypheredAuth = JSON.stringify({
      ...auth,
      password: btoa(auth.password),
    });
    this.setSession(AUTH_KEY, cypheredAuth);
  }

  setLocal(key: string, value: string): void {
    if (!this.availableLocalStorage) {
      return;
    }
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.warn(STORAGE_ERROR_MESSAGE);
    }
  }

  getLocal(key: string): string | null {
    if (!this.availableLocalStorage) {
      return null;
    }
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.warn(STORAGE_ERROR_MESSAGE);
      return null;
    }
  }

  clear(): void {
    if (!this.availableLocalStorage) {
      return;
    }
    try {
      localStorage.clear();
      this.appStorage = {};
    } catch (error) {
      console.warn(STORAGE_ERROR_MESSAGE);
    }
  }

  setSession(key: string, value: string): void {
    this.appStorage[key] = value;
  }

  getSession(key: string): string {
    return this.appStorage[key];
  }
}
