import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOf',
})
export class IndexOfPipe implements PipeTransform {
  transform(objectList: number[], key: number): number {
    return objectList.indexOf(key);
  }
}
