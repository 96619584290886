import { transcriptionsDictionary } from 'altru-types';

export type UiCardTag = {
  kind: 'primary' | 'secondary';
  category: string;
  value: string;
  id: number;
};

export type UiCard = {
  id: number;
  title: string;
  videoUrl: string;
  thumbnailUrl: string;
  gifUrl: string;
  duration: number;
  width: number;
  height: number;
  firstName: string;
  jobTitle: string;
  avatarUrl: string;
  orientation: 'portrait' | 'landscape';
  ccUrls: transcriptionsDictionary | Record<string, never>;
  ctaText: string;
  ctaHref: string;
  likes: number;
  hasLiked: boolean;
  tags: UiCardTag[];
  transcript: string;
  date: string;
  sharingLink?: string;
  __sourceObject: any;
};

export type UiCardSearchResult = {
  card: UiCard;
  csvTags: string;
  rank: number;
};

export type ConvertedUiCard<T extends object, K extends string> = UiCard & {
  __sourceObject: T;
  __kind: K;
};

export type CardEvent = { event?: KeyboardEvent | MouseEvent; card: UiCard };
export type CardSelectedEvent = CardEvent;
export type CardSharedEvent = CardEvent & {
  network: NetworkName;
  networkUrl: string;
};
export type CardLikedEvent = CardEvent;
export type CardCTAClickedEvent = CardEvent;

export type NetworkName = 'facebook' | 'twitter' | 'linkedin';

export const NETWORK_MAP: { [key in NetworkName]: string } = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?url=',
  linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
} as const;

export const EMPTY_CARD: UiCard = {
  id: 0,
  title: '',
  videoUrl: '',
  thumbnailUrl: '',
  gifUrl: '',
  duration: 0,
  width: 0,
  height: 0,
  firstName: '',
  jobTitle: '',
  avatarUrl: '',
  orientation: 'portrait',
  ccUrls: {},
  ctaText: '',
  ctaHref: '',
  likes: 0,
  hasLiked: false,
  tags: [],
  transcript: '',
  date: '',
  __sourceObject: '',
};
