import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IErrors } from '..';

type SelectDirection = 'top' | 'bottom';
type SelectValue = string | number | boolean | undefined;
interface ISelectOption {
  label: string;
  value: SelectValue;
}

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @Input()
  value: SelectValue;

  @Input()
  label: string;

  @Input()
  inputId: string;

  @Input()
  tooltip: string;

  @Input()
  placeholder: string;

  @Input()
  errors: IErrors;

  @Input()
  inputFormControl: UntypedFormControl;

  @Input()
  options: ISelectOption[] & SelectValue[];

  @Input()
  direction: SelectDirection = 'bottom';

  @Input()
  default: SelectValue;

  @Input()
  cleanOnSelect = false;

  @Input()
  variant: 'mini' | undefined;

  @Input()
  tabbable: boolean;

  @HostBinding('style.disabled')
  @Input()
  disabled: boolean;

  @HostBinding('class.mini')
  get isMiniClass(): boolean {
    return this.variant === 'mini';
  }

  @Output()
  selected: EventEmitter<SelectValue> = new EventEmitter<SelectValue>();

  @Output()
  optionEnter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  optionLeave: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:click', ['$event'])
  onDocumentClickListener: ($event: MouseEvent) => void = this.onDocumentClick;

  @ViewChildren('selectOption')
  selectOptions: QueryList<ElementRef>;

  opened = false;

  constructor(private elementRef: ElementRef) {}

  onChange: (value: SelectValue) => void = (value: number) => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    if (!this.default && !this.placeholder && this.options.length > 0) {
      this.value = this.options[0].value || this.options[0];
    } else {
      this.writeValue(this.default || undefined);
    }
  }

  writeValue(value: SelectValue): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: SelectValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  closeSelector(): void {
    this.opened = false;
  }

  openSelector(): void {
    this.opened = true;
  }

  alterSelector(): void {
    if (this.opened) {
      this.closeSelector();
    } else {
      this.openSelector();
    }
  }

  getLabel(value: SelectValue): string {
    if (this.options && this.options.length > 0) {
      const isObject: boolean = typeof this.options[0] === 'object';
      if (isObject) {
        const option: ISelectOption | undefined = this.options.find(
          _option => _option.value === value
        );
        return option ? option.label : '';
      } else {
        return value as string;
      }
    }
    return '';
  }

  onValueSelected(option: SelectValue | ISelectOption): void {
    const value: SelectValue =
      (option as ISelectOption).value !== undefined
        ? (option as ISelectOption).value
        : (option as SelectValue);

    this.writeValue(value);
    this.selected.emit(value);
    this.closeSelector();
    if (this.cleanOnSelect) {
      delete this.value;
    }
  }

  optionMouseEnter(option: any): void {
    this.optionEnter.emit(option);
  }

  optionMouseLeave(option: any): void {
    this.optionLeave.emit(option);
  }

  keypressSelector(event: KeyboardEvent): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      this.alterSelector();
    }
  }

  keydownOption(event: KeyboardEvent, option: SelectValue): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.onValueSelected(option);
    } else if (event.code === 'Tab') {
      const isFirst = this.options.indexOf(option) === 0;
      const isLast = this.options.indexOf(option) === this.options.length - 1;
      if ((!event.shiftKey && isLast) || (event.shiftKey && isFirst)) {
        this.closeSelector();
      }
    }
  }

  private onDocumentClick($event: MouseEvent): void {
    if (this.opened && !this.elementRef.nativeElement.contains($event.target)) {
      this.closeSelector();
    }
  }
}
