enum Breakpoints {
  zero = '(max-width: 0px)',
  xs = '(min-width: 1px) and (max-width: 599px)',
  sm = '(min-width: 600px) and (max-width: 959px)',
  md = '(min-width: 960px) and (max-width: 1199px)',
  lg = '(min-width: 1200px) and (max-width: 1439px)',
  xl = '(min-width: 1440px)',
}

export default Breakpoints;
