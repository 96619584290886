import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: number, args?: any): any {
    return new Date(duration * 1000).toISOString().substr(15, 4);
  }
}
