import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { transcriptionsDictionary } from 'altru-types/@types';

@Component({
  selector: 'altru-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  @Input() autoplay = false;
  @Input() src = '';
  @Input() poster = '';
  @Input() transcript = '';
  @Input() originalVideoSize = '';
  @Input() backgroundColor = '';
  @HostBinding('class.embedded')
  @Input()
  embedded = false;

  @Input() duration;
  @Input() ccUrls: transcriptionsDictionary;
  @Input() exportedVideo;
  audioContext: AudioContext | null = null;
  videoElement: HTMLVideoElement | null = null;
  isUserInteraction = false;
  constructor() {}
  ngAfterViewInit(): void {
    document.body.addEventListener('click', () => {
      this.isUserInteraction = true;
      this.setupAudioContext();
    });

    this.setupVideoElement();
  }

  setupAudioContext(): void {
    if (this.isUserInteraction && this.videoElement) {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.AudioContext)();
        const audioSource = this.audioContext.createMediaElementSource(
          this.videoElement
        );
        const gainNode = this.audioContext.createGain();
        audioSource.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
        gainNode.gain.value = 2;
      }
    }
  }
  setupVideoElement(): void {
    if (this.exportedVideo) {
      const shadowRoot = this.videoPlayer.nativeElement.shadowRoot;
      this.videoElement = shadowRoot
        ? shadowRoot.querySelector('video')
        : this.videoPlayer.nativeElement.querySelector('video');
    }
  }
}
