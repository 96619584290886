import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { IconsModule } from '@shared/modules/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentEntryModule } from '@shared/directives/component-entry/component-entry.module';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, IconsModule, TranslateModule, ComponentEntryModule],
  exports: [ModalComponent],
})
export class ModalModule {}
