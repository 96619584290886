import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Answer, Template, Client } from 'altru-types/@types';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments';

type NetworkName = 'facebook' | 'twitter' | 'linkedin';
@Component({
  selector: 'ui-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss'],
})
export class SocialButtonsComponent implements OnChanges {
  constructor(@Inject(DOCUMENT) private document: any) {}

  @Input()
  answer: Answer;

  @Input()
  template: Template;

  @Input()
  client: Client;

  @Input()
  tabbable: boolean;

  @Output() likedAnswer: EventEmitter<Answer> = new EventEmitter<Answer>();
  @Output() likesButtonTabulated: EventEmitter<{
    answer: Answer;
    event: KeyboardEvent;
  }> = new EventEmitter<{ answer: Answer; event: KeyboardEvent }>();

  networks: { [key in NetworkName]: string } = {
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    twitter: 'https://twitter.com/intent/tweet?url=',
    linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.tabbable &&
      changes.tabbable.previousValue !== changes.tabbable.currentValue
    ) {
      const shareButtons: NodeListOf<HTMLElement> = this.document.querySelectorAll(
        '.sb-wrapper'
      );
      shareButtons.forEach(button => {
        button.setAttribute(
          'tabindex',
          changes.tabbable.currentValue ? '0' : '-1'
        );
      });
    }
  }

  likeAnswer(e: Event, answer: Answer): void {
    e.stopPropagation();
    if (answer.has_liked) {
      return;
    }
    this.likedAnswer.emit(answer);
  }

  likesButtonKeydown(answer: Answer, event: KeyboardEvent): void {
    if (event.code === 'Tab' && !event.shiftKey) {
      this.likesButtonTabulated.emit({ answer, event });
    }
  }

  share(
    e: Event,
    client: Client,
    template: Template,
    answer: Answer,
    networkName: NetworkName
  ) {
    e.stopPropagation();
    const shareURL = this.networks[networkName];
    const answerURL = encodeURIComponent(
      `${environment.baseUrl}/${client.slug}/${template.slug}?answer_id=${answer.id}`
    );
    window.open(`${shareURL}${answerURL}`, '_blank');
  }
}
