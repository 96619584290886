/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { Video } from 'altru-types';

export const SET = '[Videos] Set';
export const REPLACE_VIDEO = '[Videos] Replace video';

export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: Video[]) {}
}

export class ReplaceVideo implements Action {
  readonly type: string = REPLACE_VIDEO;
  constructor(public payload: Video) {}
}

export type All = Set | ReplaceVideo;
