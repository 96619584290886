import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivationEnd,
  GuardsCheckEnd,
  GuardsCheckStart,
  ResolveEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { StoreShape } from '@store/action-reducers';
import { AvailableLang } from '@store/api-lang/api-lang.actions';

import {
  ModalService,
  FeedService,
  LoadingService,
  WidgetMessageService,
  ApiLangService,
  ClientService,
} from '@services';

import { State as UIState } from '@store/ui/ui.reducer';
import Breakpoints from './config/app-settings';
import * as UIActions from '@store/ui/ui.actions';
import { breakpointSize } from '@store/ui/ui.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { MixpanelService } from '@modules/track/mixpanel/mixpanel.service';

export type BreakpointListeners = {
  listeners: {
    mediaQueryList: MediaQueryList;
    listener: (event: MediaQueryListEvent) => void;
    usesDeprecatedAddListener: boolean;
  }[];
  stopListening: () => void;
};

@Component({
  selector: 'altru-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // routing before the app has finished booting (and activating the route)
  // causes a bug where we drop the path to the feed and wind up at the
  // not-found page.
  initializedRoute = false;

  private untilDestroyed = new Subscription();
  private browserWidth;
  private breakpointListeners: BreakpointListeners = {
    listeners: [],
    stopListening: () => {},
  };

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private clientService: ClientService,
    private titleService: Title,
    private apiLangService: ApiLangService,
    private widgetMessageService: WidgetMessageService,
    private activatedRoute: ActivatedRoute,
    private feedService: FeedService,
    private store: Store<StoreShape>,
    private modalService: ModalService,
    private mediaMatcher: MediaMatcher,
    private mixpanelService: MixpanelService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  get ui$(): Observable<UIState> {
    return this.store.select('ui');
  }

  @HostListener('window:keyup', ['$event'])
  private onKeyUp($event: KeyboardEvent): void {
    if ($event.code === 'Escape') {
      this.ui$.pipe(first()).subscribe(uiState => {
        if (
          uiState.dialogVisibility &&
          this.modalService.modal?.isCloseButtonVisible
        ) {
          this.modalService.close();
        } else {
          this.widgetMessageService.closeIframe();
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any): void {
    if (this.browserWidth !== event.target.outerWidth) {
      this.browserWidth = event.target.outerWidth;
      this.feedService.sendPageHeight();
    }
  }

  ngOnInit(): void {
    // init mixpanel with shared properties from URL if exist coming from the widget
    const queryParams = window.location.search;
    const urlParams = new URLSearchParams(queryParams);
    const deviceId = urlParams.get('deviceId');
    const widgetVersion = urlParams.get('widgetVersion');
    this.mixpanelService.register({
      ...(deviceId ? { $device_id: deviceId } : {}),
      ...(deviceId ? { distinct_id: deviceId } : {}),
      ...(widgetVersion ? { widget_version: widgetVersion } : {}),
      vs_source: deviceId ? 'widget' : 'applicant',
    });

    this.bypassErrorHandler();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.apiLangService.setLang(event.lang as AvailableLang);
    });

    this.clientService.client$
      .pipe(filter(client => !!client))
      .subscribe(client => {
        // Set default lang
        const defaultLang: string =
          client && client.locale ? client.locale : 'en';
        this.translateService.setDefaultLang(defaultLang);
        this.translateService.use(defaultLang);
      });

    this.titleService.setTitle('Video Studio');

    this.untilDestroyed.add(
      this.router.events
        .pipe(
          filter(
            event =>
              event instanceof ActivationEnd ||
              event instanceof GuardsCheckEnd ||
              event instanceof GuardsCheckStart ||
              event instanceof ResolveEnd ||
              event instanceof ResolveStart
          )
        )
        .subscribe({
          next: event => {
            this.titleService.setTitle('Video Studio');
            if (
              event instanceof ResolveStart ||
              event instanceof GuardsCheckStart
            ) {
              this.loadingService.show();
            } else if (
              event instanceof ResolveEnd ||
              event instanceof GuardsCheckEnd
            ) {
              this.loadingService.hide();
            } else if (event instanceof ActivationEnd) {
              this.initializedRoute = true;
              this.loadingService.hide();
            }
          },
        })
    );

    this.untilDestroyed.add(
      this.widgetMessageService.events['Widget Clicked']?.subscribe({
        next: this.handleClick.bind(this),
      })
    );

    this.untilDestroyed.add(
      this.widgetMessageService.events['Focus Last Element']?.subscribe(() =>
        this.feedService.focusLastElement()
      )
    );

    if (isPlatformBrowser(this.platformId)) {
      this.breakpointListeners = createBreakpointListeners(
        this.mediaMatcher,
        this.store
      );
    }
    this.loadVideoPlayer();
  }
  loadVideoPlayer() {
    if (typeof window !== 'undefined' && window.customElements) {
      import('icims-video-player')
        .then(() => {
          if (!window.customElements.get('icims-video-player')) {
            window.customElements.define(
              'icims-video-player',
              class extends HTMLElement {}
            );
          }
        })
        .catch(err => console.error('Error loading icims-video-player:', err));
    }
  }
  ngOnDestroy(): void {
    this.breakpointListeners.stopListening();
    this.untilDestroyed.unsubscribe();
  }

  handleClick(data: {
    answerIndex: number;
    answersLength: number;
    answerId: number;
    videoId: number;
  }): void {
    if (!this.initializedRoute) {
      setTimeout(() => this.handleClick(data), 200);
    } else {
      const { answerId, videoId } = data;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
          answer_id: answerId,
          video_id: videoId,
        },
      });
    }
  }

  private bypassErrorHandler(): void {
    /* Fix from https://stackoverflow.com/a/49805926/5111805
      to force restart page on failed chunk loading (new app version just deployed)
    */

    // Keep the original error handler
    const oldHandler = this.router.errorHandler;
    // Replace route error handler
    this.router.errorHandler = (err: any) => {
      // Check if there is an error loading the chunk
      if (
        err.originalStack &&
        err.originalStack.indexOf('Error: Loading chunk') >= 0
      ) {
        // Check if is the first time the error happend
        if (localStorage.getItem('lastChunkError') !== err.originalStack) {
          // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
          localStorage.setItem('lastChunkError', err.originalStack);
          location.reload();
        } else {
          // The chunk really does not exists after reload
          console.error("Chunk doesn't exists before reloading");
        }
      }
      // Run original handler
      oldHandler(err);
    };
  }
}

const createBreakpointListeners = (
  mediaMatcher: MediaMatcher,
  store: Store<StoreShape>
): BreakpointListeners => {
  for (const size of Object.keys(Breakpoints)) {
    const mediaQuery = Breakpoints[size];
    if (mediaMatcher.matchMedia(mediaQuery).matches) {
      store.dispatch(
        UIActions.modifyBreakpointState({
          size: size as breakpointSize,
        })
      );
      break;
    }
  }
  const listeners = Object.keys(Breakpoints).map((size: breakpointSize) => {
    const mediaQueryList = mediaMatcher.matchMedia(Breakpoints[size]);
    const usesDeprecatedAddListener = !mediaQueryList.addEventListener;
    const watcher = {
      mediaQueryList,
      listener: (event: MediaQueryListEvent) => {
        if (event.matches) {
          store.dispatch(
            UIActions.modifyBreakpointState({
              size,
            })
          );
        }
      },
      usesDeprecatedAddListener,
    };
    if (usesDeprecatedAddListener) {
      // eslint-disable-next-line import/no-deprecated
      mediaQueryList.addListener(watcher.listener);
    } else {
      mediaQueryList.addEventListener('change', watcher.listener);
    }
    return watcher;
  });

  const stopListening = () => {
    listeners.forEach(
      ({ mediaQueryList, listener, usesDeprecatedAddListener }) => {
        if (usesDeprecatedAddListener) {
          // eslint-disable-next-line import/no-deprecated
          mediaQueryList.removeListener(listener);
        } else {
          mediaQueryList.removeEventListener('change', listener);
        }
      }
    );
  };

  return {
    listeners,
    stopListening,
  };
};
