import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ToggleComponent } from '@shared/components/toggle/toggle.component';
import { transcriptionLabel } from 'altru-types/@types';
import { VideoOverlayComponent } from '../video-overlay/video-overlay.component';

@Component({
  selector: 'altru-video-captions-menu',
  templateUrl: './video-captions-menu.component.html',
  styleUrls: ['./video-captions-menu.component.scss'],
})
export class VideoCaptionsMenuComponent {
  @Input() localeLabels: object;
  @Input() localeKeys: transcriptionLabel[] = ['en'];
  @Input() captionsEnabled = false;
  @Input() captionLang: transcriptionLabel = this.localeKeys[0];
  @Input() videoHeight: number;
  @Input() minVideoSize = { minWidth: true, minHeight: true };

  @Output() toggledCaptions: EventEmitter<boolean | null> = new EventEmitter<
    boolean | null
  >();
  @Output()
  selectedCaption: EventEmitter<transcriptionLabel | null> = new EventEmitter<
    transcriptionLabel
  >();

  @ViewChild('toggle', { static: false })
  toggle: ToggleComponent;

  setTrack(langKey: transcriptionLabel): void {
    this.toggledCaptions.emit(true);
    this.selectedCaption.emit(langKey);
  }

  toggleCaptions(enabled: boolean): void {
    this.toggledCaptions.emit(enabled);
  }

  toggleOverlay(
    event: KeyboardEvent | PointerEvent,
    button: ElementRef<HTMLButtonElement>,
    overlay: VideoOverlayComponent
  ): void {
    const spacebarPressed =
      event.type === 'keyup' && (event as KeyboardEvent).code === 'Space';
    const buttonClicked = event.type === 'click';

    if (spacebarPressed || buttonClicked) {
      event.preventDefault();
      overlay.toggleOverlay(button);
      if (overlay.overlayOpened) {
        this.toggle.focus();
      }
    }
  }
}
