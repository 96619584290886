import {
  Component,
  OnDestroy,
  Input,
  NgZone,
  Renderer2,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'altru-video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss'],
})
export class VideoOverlayComponent implements OnDestroy {
  @Input()
  videoHeight: number;

  @HostBinding('class.small')
  @Input()
  small = false;

  constructor(private ngZone: NgZone, private renderer: Renderer2) {}

  overlayOpened = false;
  eventUnsubscriber: () => void = () => {};

  maxHeight: (videoHeight: number) => string = (videoHeight: number): string =>
    `${videoHeight - videoHeight / 3}px`;

  openOverlay(element: any): void {
    if (!this.overlayOpened) {
      this.overlayOpened = true;
      this.ngZone.runOutsideAngular(() => {
        this.eventUnsubscriber = this.renderer.listen(
          window,
          'click',
          (ev: Event) => {
            if (!element.contains(ev.target)) {
              this.ngZone.run(() => {
                this.overlayOpened = false;
              });
              this.eventUnsubscriber();
              this.eventUnsubscriber = () => {};
            }
          }
        );
      });
    }
  }

  toggleOverlay(element): void {
    if (this.overlayOpened) {
      this.overlayOpened = false;
    } else {
      this.openOverlay(element);
    }
  }

  ngOnDestroy(): void {
    this.eventUnsubscriber();
    this.eventUnsubscriber = () => {};
  }
}
