import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  BreadcrumbList,
  ItemList,
  ListItem,
  VideoObject,
  WithContext,
} from 'schema-dts';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  descriptionMetaElement: HTMLMetaElement;
  canonicalUrlLinkElement: HTMLLinkElement;
  breadcrumbsScriptElement: HTMLScriptElement;
  structuredDataScriptElement: HTMLScriptElement;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initializedescriptionMetaElement();
      this.initializeCanonicalUrlLinkElement();
      this.initializeBreadcrumbsScriptElement();
      this.initializeStructuredDataScriptElement();
    }
  }

  private initializedescriptionMetaElement() {
    this.descriptionMetaElement = this.document.createElement('meta');
    this.descriptionMetaElement.setAttribute('name', 'description');
    this.descriptionMetaElement.setAttribute(
      'content',
      'The Video Studio application'
    );

    this.document.head.appendChild(this.descriptionMetaElement);
  }

  private initializeCanonicalUrlLinkElement() {
    this.canonicalUrlLinkElement = this.document.createElement('link');
    this.canonicalUrlLinkElement.setAttribute('rel', 'canonical');
    this.canonicalUrlLinkElement.setAttribute(
      'href',
      this.document.URL.split('?')[0]
    );

    this.document.head.appendChild(this.canonicalUrlLinkElement);
  }

  private initializeBreadcrumbsScriptElement() {
    this.breadcrumbsScriptElement = this.document.createElement('script');
    this.breadcrumbsScriptElement.setAttribute('type', 'application/ld+json');

    this.document.head.appendChild(this.breadcrumbsScriptElement);
  }

  private initializeStructuredDataScriptElement() {
    this.structuredDataScriptElement = this.document.createElement('script');
    this.structuredDataScriptElement.setAttribute(
      'type',
      'application/ld+json'
    );

    this.document.head.appendChild(this.structuredDataScriptElement);
  }

  setCanonicalUrlLink(getParams?: string) {
    const canonicalUrl = getParams
      ? `${this.document.URL.split('?')[0]}?${getParams}`
      : this.document.URL.split('?')[0];

    if (this.canonicalUrlLinkElement) {
      this.canonicalUrlLinkElement.setAttribute('href', canonicalUrl);
    }
  }

  setDescriptionMeta(
    metaDescription?: string,
    metaTags?: { [label: string]: string | number }
  ) {
    let metaDescriptionText = metaDescription || 'The Video Studio application';
    metaDescriptionText += Object.keys(metaTags || {})
      .map(metaTag => ` ${metaTag}: ${(metaTags ?? {})[metaTag]}`)
      .join(',');

    if (this.descriptionMetaElement) {
      this.descriptionMetaElement.setAttribute('content', metaDescriptionText);
    }
  }

  setBreadcrumbsScript(breadcrumbs: ListItem[]) {
    const breadcrumblist: WithContext<BreadcrumbList> = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs,
    };

    if (this.breadcrumbsScriptElement) {
      this.breadcrumbsScriptElement.textContent = JSON.stringify(
        breadcrumblist
      );
    }
  }

  setStructuredDataScript(structuredData: VideoObject | ItemList) {
    const videoObject: WithContext<VideoObject | ItemList> = {
      '@context': 'https://schema.org',
      ...structuredData,
    };

    if (
      this.structuredDataScriptElement &&
      this.structuredDataIsValid(structuredData)
    ) {
      this.structuredDataScriptElement.textContent = JSON.stringify(
        videoObject
      );
    }
  }

  private structuredDataIsValid(
    structuredData: VideoObject | ItemList
  ): boolean {
    if (structuredData['@type'] === 'VideoObject') {
      // These required fields are based on Google's structured data requirements
      // https://developers.google.com/search/docs/advanced/structured-data/video#video-object
      const requiredKeys = [
        'description',
        'name',
        'thumbnailUrl',
        'uploadDate',
      ];

      return requiredKeys.reduce(
        (dataIsValid, requiredKey) =>
          dataIsValid && requiredKey in structuredData,
        true
      );
    } else if (structuredData['@type'] === 'BreadcrumbList') {
      // https://developers.google.com/search/docs/advanced/structured-data/breadcrumb#breadcrumb-list
      return 'itemListElement' in structuredData;
    }

    return false;
  }
}
