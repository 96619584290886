import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Template } from 'altru-types';
import {
  CardCTAClickedEvent,
  CardLikedEvent,
  CardSelectedEvent,
  CardSharedEvent,
  EMPTY_CARD,
  UiCard,
} from './card-types';

@Component({
  selector: 'ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input()
  card: UiCard = EMPTY_CARD;

  @Input()
  tabbable = false;

  @Input()
  template: Template;

  @Input()
  highlightText = '';

  @Output()
  selected = new EventEmitter<CardSelectedEvent>();

  @Output()
  liked = new EventEmitter<CardLikedEvent>();

  @Output()
  shared = new EventEmitter<CardSharedEvent>();

  @Output()
  ctaClicked = new EventEmitter<CardCTAClickedEvent>();

  onClick(event: MouseEvent, card: UiCard) {
    event.preventDefault();
    event.stopPropagation();
    this.selected.emit({ event, card });
  }

  onKeyup(event: KeyboardEvent, card: UiCard) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      this.selected.emit({ event, card });
    }
  }

  onShared(event: CardSharedEvent): void {
    this.shared.emit(event);
  }

  onLiked(event: CardLikedEvent): void {
    this.liked.emit(event);
  }

  onCtaClicked(event: CardCTAClickedEvent): void {
    this.ctaClicked.emit(event);
  }
}
