import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'ui-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() disabled = false;

  @ViewChild('toggle', { static: false })
  toggle: ElementRef<HTMLButtonElement>;

  enabled = false;
  onChange = _ => {};
  onBlur = _ => {};

  writeValue(obj: boolean): void {
    this.enabled = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged(value) {
    this.enabled = value;
    this.onChange(this.enabled);
  }

  focus(): void {
    setTimeout(() => {
      this.toggle.nativeElement.focus();
    }, 50);
  }
}
