import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IErrors {
  [label: string]: string;
}

@Component({
  selector: 'ui-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.scss'],
})
export class InputErrorsComponent {
  @Input()
  inputFormControl: UntypedFormControl;

  @Input()
  errors: IErrors;

  constructor() {}

  isErrorsContainerVisible(): boolean {
    return (
      this.inputFormControl.invalid &&
      (this.inputFormControl.dirty || this.inputFormControl.touched)
    );
  }

  getFirstError(): string | undefined {
    for (const errorKey of Object.keys(this.errors)) {
      if (
        this.inputFormControl.errors &&
        this.inputFormControl.errors[errorKey]
      ) {
        return this.errors[errorKey];
      }
    }

    return undefined;
  }
}
