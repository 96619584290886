import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments';

import {
  ApplicantStatus,
  ITrackService,
  TrackEvent,
  TrackEvents,
} from '@modules/track/track.service';
import { ClientService, clientToRegion } from '@services';

import { Client } from 'altru-types/@types';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

type integer = number;
type SnowplowData = {
  altru_client_id: number;
  environment: string;
  app_name: 'applicant';
  app_version: string;
  action: string;
  additional_info: {
    answer_id?: integer;
    widget_id?: integer;
    question_id?: integer;
    widget_type?: string;
    event_properties: object;
  };
};

const snowplow = (): any | undefined =>
  // don't do anything in ssr!
  ('undefined' !== typeof window || undefined) &&
  (window as any)._jibe?.session &&
  (window as any)._jibe;

@Injectable()
export class SnowplowService implements ITrackService {
  queuedEvents: SnowplowData[] = [];

  constructor(
    @Inject(DOCUMENT) private universalDocument: any,
    clientService: ClientService
  ) {
    // we bail if we are in ssr or if _jibe somehow loaded already
    if ('undefined' === typeof window || snowplow()) {
      return;
    }

    clientService.client$
      .pipe(
        take(1),
        mergeMap((client: Client) => {
          // _jibe checks config *initialization*. cannot configure _jibe after load
          this.setupSnowplow({ globalRegion: clientToRegion(client) });

          return this.loadSnowplow(environment.snowplowUrl);
        })
      )
      .subscribe({
        next: event => {
          // _jibe takes a while to boot, so don't use snowplow() to run this check
          if (!(window as any)._jibe) {
            const error = new Error('_jibe is unavailable');
            (error as any).script_event = event;
            throw error;
          }
        },
      });

    // snowplow needs some time to initialize so put the
    // event processing on the event queue to give it space
    // we use a slowly increasing timer so out of respect for
    // the users phone/computer battery
    let count = 0;
    const processWhenLoaded = () => {
      if (snowplow()) {
        this.processEvents();
      } else {
        count += 1;
        setTimeout(processWhenLoaded, count);
      }
    };
    processWhenLoaded();
  }

  loadSnowplow = (src: string): Observable<Event> =>
    new Observable<Event>(sub => {
      if (!src) {
        sub.error(new Error('You must provide a src URL for _jibe'));
      } else {
        const snowplowScript = this.universalDocument.createElement('script');
        snowplowScript.src = src;
        snowplowScript.onload = (...args: any[]) => {
          sub.next(...args);
          sub.complete();
        };
        snowplowScript.onerror = sub.error.bind(sub);
        this.universalDocument.head.appendChild(snowplowScript);
      }
    });

  processEvents() {
    if (!snowplow()) {
      return;
    }

    const unprocessedEvents = this.queuedEvents;
    this.queuedEvents = [];

    for (const eventData of unprocessedEvents) {
      snowplow().trackEvent(
        'com.icims',
        'video_studio_event',
        '1-0-4',
        eventData
      );
    }
  }

  setupSnowplow({ globalRegion = 'us' }: { globalRegion?: string }) {
    // just be safe, even if we shouldn't get here without a window
    if ('undefined' === typeof window) {
      return;
    }
    const snowplowConfig = {
      cid: 'altru',
      analytics: { isTrackingEnabled: true, isExplicitTrackingOnly: true },
      env: environment.env,
      region: globalRegion,
    };

    const _jibe = snowplow || {};

    // we can't assign to snowplow() because that will now update the object in window
    (window as any)._jibe = { ..._jibe, ...snowplowConfig };
  }

  track(
    action: TrackEvent,
    status: ApplicantStatus,
    properties?: object
  ): void {
    const snowplowTrackingProps: SnowplowData['additional_info'] = {
      widget_id: status.widgetId ? +status.widgetId : 0,
      widget_type: status.widgetType,
      event_properties: properties ?? {},
    };

    const eventData: SnowplowData = {
      action: TrackEvents[action],
      additional_info: snowplowTrackingProps,
      altru_client_id: status.clientId,
      app_name: 'applicant',
      app_version: '',
      environment: environment.env,
    };

    this.queuedEvents.push(eventData);
    this.processEvents();
  }
}
