import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import * as Components from './components';
import { ModalService } from '@services';
import { RearrangePipe } from './pipes/rearrange.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { IndexOfPipe } from './pipes/index-of.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { VideoPlayerModule } from './components/video-player/video-player.module';
import { PurePipeModule } from './pipes/pure';
import { IconsModule } from './modules/icons.module';
import { DurationPipeModule } from './pipes/duration/duration-pipe.module';
import { LoadingDirectiveModule } from './directives/loading/loading-directive.module';
import { BackgroundDirectiveModule } from './directives/background/background-directive.module';
import { CardModule } from './components/card/card.module';
import { DetailViewModule } from './components/detail-view/detail-view.module';
import { ModalModule } from './components/modal/modal.module';
import { ComponentEntryModule } from './directives/component-entry/component-entry.module';
import { SnackbarService } from '@services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    VideoPlayerModule,
    PurePipeModule,
    DurationPipeModule,
    IconsModule,
    LoadingDirectiveModule,
    BackgroundDirectiveModule,
    CardModule,
    DetailViewModule,
    ModalModule,
    ComponentEntryModule,
  ],
  declarations: [
    Components.LoaderComponent,
    Components.CheckboxComponent,
    Components.InputComponent,
    Components.InputContainerComponent,
    Components.InputErrorsComponent,
    Components.LabelComponent,
    Components.LoaderComponent,
    Components.SelectComponent,
    Components.LoadingComponent,
    Components.SocialButtonsComponent,
    RearrangePipe,
    SortPipe,
    IndexOfPipe,
    TrimPipe,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    Components.LoaderComponent,
    Components.CheckboxComponent,
    Components.InputComponent,
    Components.InputContainerComponent,
    Components.InputErrorsComponent,
    Components.LabelComponent,
    Components.LoaderComponent,
    Components.SelectComponent,
    Components.LoadingComponent,
    Components.SocialButtonsComponent,
    RearrangePipe,
    SortPipe,
    IndexOfPipe,
    TrimPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ModalService, SnackbarService],
    };
  }
}
