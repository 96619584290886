import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { VideoPlayerComponent } from './video-player.component';
import { IconsModule } from '../../modules/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleComponent } from '../toggle/toggle.component';
import { VideoControlsComponent } from './video-controls/video-controls.component';
import { VideoCaptionsMenuComponent } from './video-controls/video-captions-menu/video-captions-menu.component';
import { VideoVolumeComponent } from './video-controls/video-volume/video-volume.component';
import { VideoTranscriptComponent } from './video-controls/video-transcript/video-transcript.component';
import { SliderComponent } from '../slider/slider.component';
import { ResizeObserverDirective } from '../../directives/resize-observer/resize-observer.directive';
import { PurePipeModule } from '../../pipes/pure';
import { VideoOverlayComponent } from './video-controls/video-overlay/video-overlay.component';
import { PipesModule } from 'src/app/features/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IconsModule,
    PurePipeModule,
    PipesModule,
  ],
  declarations: [
    VideoPlayerComponent,
    VideoControlsComponent,
    VideoCaptionsMenuComponent,
    VideoVolumeComponent,
    VideoTranscriptComponent,
    VideoOverlayComponent,
    ToggleComponent,
    SliderComponent,
    ResizeObserverDirective,
  ],
  exports: [VideoPlayerComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VideoPlayerModule {}
