import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { VideoOverlayComponent } from '../video-overlay/video-overlay.component';

@Component({
  selector: 'altru-video-transcript',
  templateUrl: './video-transcript.component.html',
  styleUrls: ['./video-transcript.component.scss'],
})
export class VideoTranscriptComponent {
  @Input() transcript = '';
  @Input() videoHeight = 0;
  @Input() minVideoSize = { minWidth: true, minHeight: true };

  @ViewChild('transcriptContent', { static: false })
  transcriptContent: ElementRef<HTMLDivElement>;

  toggleOverlay(
    event: KeyboardEvent | PointerEvent,
    button: ElementRef<HTMLButtonElement>,
    overlay: VideoOverlayComponent
  ): void {
    if (
      (event.type === 'keyup' && (event as KeyboardEvent).code === 'Space') ||
      event.type === 'click'
    ) {
      event.preventDefault();
      overlay.toggleOverlay(button);

      if (overlay.overlayOpened) {
        setTimeout(() => {
          this.transcriptContent.nativeElement.focus();
        }, 50);
      }
    }
  }
}
