import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IErrors } from '..';

import { HelperService } from '@services';

type InputType = 'text' | 'password' | 'email' | 'date';

@Component({
  selector: 'ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    HelperService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input()
  inputId: string;

  @Input()
  formControlName: string;

  @Input()
  tooltip: string;

  @Input()
  set type(type: InputType) {
    if (type === 'date' && this.helperService.getBrowserName() === 'IE') {
      this.fieldType = 'text';
    } else if (!type) {
      this.fieldType = 'text';
    } else {
      this.fieldType = type;
    }
  }

  @Input()
  preIcon: string;

  @Input()
  postIcon: string;

  @Input()
  placeholder = '';

  @Input()
  label = '';

  @Input()
  inputFormControl: UntypedFormControl;

  @Input()
  autocomplete = 'off';

  @Input()
  errors: IErrors;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @HostBinding('class.big')
  @Input()
  big = false;

  fieldType: string;

  value: string | number = '';

  constructor(public helperService: HelperService) {}

  onChange: (value: string | number) => void = (value: string) => {};
  onTouched: () => void = () => {};

  writeValue(value: string | number): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
