import { Injectable } from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AltruApiResponse, Template } from 'altru-types';
import { HelperService, TemplateService } from '@services';

export const TEMPLATE_RESPONSE_KEY: StateKey<AltruApiResponse<
  Template
>> = makeStateKey('template');

@Injectable()
export class TemplateResolverService
  implements Resolve<AltruApiResponse<Template>> {
  constructor(
    private helperService: HelperService,
    private templateService: TemplateService,
    private transferState: TransferState
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<AltruApiResponse<Template>> {
    const templateRes: AltruApiResponse<
      Template
    > | null = this.transferState.get<AltruApiResponse<Template> | null>(
      TEMPLATE_RESPONSE_KEY,
      null
    );

    return templateRes
      ? of(templateRes).pipe(
          tap(
            res =>
              res.data &&
              this.templateService.storeTemplate(res.data, route.queryParams)
          )
        )
      : this.templateService
          .getTemplate(
            route.params.client_slug,
            route.params.feed_name,
            route.queryParams
          )
          .pipe(
            tap(res => {
              this.transferState.set<AltruApiResponse<Template>>(
                TEMPLATE_RESPONSE_KEY,
                res
              );
              this.helperService.setCssVars();
            })
          );
  }
}
