import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rearrange',
})
export class RearrangePipe implements PipeTransform {
  transform(items: any[], columns: number): any {
    const rearrangedArray: any[] = [];
    let col = 0;
    while (col < columns) {
      const newCol: any[] = [];
      for (let i = 0; i < items.length; i += columns) {
        const foundItem = items[i + col];
        if (foundItem !== undefined) {
          newCol.push(foundItem);
        }
      }
      rearrangedArray.push(newCol);
      col++;
    }
    return rearrangedArray;
  }
}
