import * as R from 'ramda';
import { Answer } from 'altru-types';
import * as AnswersActions from './answers.actions';

type Action = AnswersActions.All;

export const INITIAL_STATE: Answer[] = [];

export function answersReducer(
  state: Answer[] = INITIAL_STATE,
  action: Action
): Answer[] {
  switch (action.type) {
    case AnswersActions.SET:
      return R.clone(action.payload as Answer[]);
    case AnswersActions.REPLACE_ANSWER:
      const replacedAnswer: Answer = action.payload as Answer;
      return R.update(
        R.findIndex(R.propEq('id', replacedAnswer.id))(state),
        replacedAnswer
      )(state);
    default:
      return state;
  }
}
