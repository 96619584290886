/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';

export const CLEAN = '[Store] Clean';

export class Clean implements Action {
  readonly type: string = CLEAN;
  readonly payload: null;
}

export type All = Clean;
