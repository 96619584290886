import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { first, mergeMap, map, skipWhile } from 'rxjs/operators';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { StorageService, TemplateService } from '@services';
import { Template } from 'altru-types/@types';

@Component({
  selector: 'altru-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
})
export class PasswordModalComponent implements OnInit {
  rememberMe = true;
  requestError = '';
  loading = false;
  passwordForm: UntypedFormGroup;

  @Output()
  authSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private templateService: TemplateService,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

  onSubmit(): void {
    const formValues = this.passwordForm.getRawValue();
    if (this.passwordForm.valid) {
      this.requestError = '';
      this.loading = true;
      this.templateService.template$
        .pipe(
          skipWhile(template => template === null),
          first(),
          mergeMap((template: Template) =>
            this.templateService
              .verify(template.id, {
                password: formValues.password,
              })
              .pipe(map(verifyRes => ({ verifyRes, template })))
          )
        )
        .subscribe(
          ({ template }) => {
            const auth = {
              templateId: template.id,
              password: formValues.password,
            };
            if (this.rememberMe) {
              this.storageService.templatePassword = auth;
            } else {
              this.storageService.templatePasswordSession = auth;
            }
            this.authSuccess.emit();
            this.loading = false;
          },
          error => {
            this.requestError = 'SORRY, THAT PASSWORD IS INCORRECT';
            this.storageService.clear();
            this.loading = false;
          }
        );
    }
  }
}
