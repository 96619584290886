import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Template } from 'altru-types/@types';
import {
  CardCTAClickedEvent,
  CardLikedEvent,
  CardSharedEvent,
  EMPTY_CARD,
  UiCard,
  UiCardTag,
} from '@shared/components';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent {
  @Input()
  card: UiCard = EMPTY_CARD;

  @Input()
  template: Template | null = null;

  @Input()
  tabbable = false;

  @Input()
  tagsHighlightText = '';

  @Output()
  liked = new EventEmitter<CardLikedEvent>();

  @Output()
  shared = new EventEmitter<CardSharedEvent>();

  @Output()
  ctaClicked = new EventEmitter<CardCTAClickedEvent>();

  onLike(event: CardLikedEvent): void {
    this.liked.emit(event);
  }

  onShare(event: CardSharedEvent): void {
    this.shared.emit(event);
  }

  onCtaClicked(event: MouseEvent | KeyboardEvent, card: UiCard): void {
    event.stopPropagation();

    this.ctaClicked.emit({
      event,
      card,
    });
  }

  checkIndex(_index: number, item: UiCardTag): number {
    return item.id;
  }

  moreTagsTitle = moreTagsTitle;

  constructor(private activatedRoute: ActivatedRoute) {}

  get isShowingInModal(): boolean {
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    const { answer_id, video_id } = currentQueryParams;
    return !!answer_id || !!video_id;
  }
}

const moreTagsTitle = (tags: UiCardTag[]) =>
  tags.map(tag => tag.value).join(', ');
