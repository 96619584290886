import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { TranslationsModule } from './config/translations/translations.module';
import { TrackModule } from '@modules/track/track.module';
import { WidgetMessageModule } from '@modules/widget-message/widget-message.module';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { OldBrowsersAdviceComponent } from './features/old-browsers-advice/old-browsers-advice.component';
import {
  ApiLangService,
  ClientService,
  StorageService,
  TemplateService,
  VideoService,
} from '@services';
import {
  SentryErrorHandler,
  LoadingService,
  FeedService,
  ModalService,
} from '@services';
import { reducers } from '@store/action-reducers';
import { metaReducer } from '@store/store.reducers';
import { environment } from '@environments';
import { IconsModule } from '@shared/modules/icons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackbarService } from './services/snackbar/snackbar.service';
import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';

@NgModule({
  declarations: [AppComponent, OldBrowsersAdviceComponent, SnackbarComponent],
  imports: [
    SharedModule.forRoot(),
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'applicant' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    ReactiveFormsModule,
    TranslationsModule,
    TrackModule.forRoot(),
    WidgetMessageModule.forRoot(),
    IconsModule,
    LayoutModule,
    StoreModule.forRoot(reducers, { metaReducers: [metaReducer] }),
    environment.devTools ? StoreDevtoolsModule.instrument() : [],
    BrowserAnimationsModule,
  ],
  providers: [
    ApiLangService,
    LoadingService,
    StorageService,
    TemplateService,
    ClientService,
    VideoService,
    FeedService,
    ModalService,
    SnackbarService,
    ...(environment.sentry
      ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
      : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(config: FaConfig) {
    config.fallbackIcon = faBan;
  }
}
