import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './ui.actions';
import { breakpointSize } from './ui.model';

export type State = {
  dialogVisibility: boolean;
  sidebarVisibility: boolean;
  breakpointSize: breakpointSize;
};

export const INITIAL_STATE: State = {
  dialogVisibility: false,
  sidebarVisibility: false,
  breakpointSize: 'zero',
};

const reducer = createReducer(
  INITIAL_STATE,
  on(
    Actions.modifyDialogState,
    (state: State, { visibility }: { visibility: boolean }) => ({
      ...state,
      dialogVisibility: visibility,
    })
  ),

  on(
    Actions.modifySidebarState,
    (state: State, { visibility }: { visibility: boolean }) => ({
      ...state,
      sidebarVisibility: visibility,
    })
  ),

  on(
    Actions.modifyBreakpointState,
    (state: State, { size }: { size: breakpointSize }) => ({
      ...state,
      breakpointSize: size,
    })
  )
);

export function uiReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
