import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreShape } from '@store/action-reducers';
import { Observable, Subject } from 'rxjs';
type Message =
  | 'Widget Loaded'
  | 'Widget Clicked'
  | 'Widget Closed'
  | 'Answer Loaded'
  | 'Window Resized'
  | 'Focus Last Element';
type MessageSubjects = { [message in Message]?: Subject<any> };
type MessageObservables = { [message in Message]?: Observable<any> };
import * as WidgetActions from '@store/widget/widget.actions';

@Injectable()
export class WidgetMessageService {
  subjects: MessageSubjects = {
    'Widget Loaded': new Subject<void>(),
    'Widget Clicked': new Subject<void>(),
    'Widget Closed': new Subject<void>(),
    'Answer Loaded': new Subject<number>(),
    'Focus Last Element': new Subject<void>(),
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private store: Store<StoreShape>
  ) {
    this.init();
  }

  init(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener(
        'message',
        event => this.processMessage(event),
        false
      );
      window.parent.postMessage('App Listening', '*');
    }
  }

  closeIframe(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.parent.postMessage('Close Iframe', '*');
    }
  }

  get events(): MessageObservables {
    const subjectKeys: string[] = Object.keys(this.subjects);
    const messageObservables: MessageObservables = {};

    for (const key of subjectKeys) {
      messageObservables[key] = this.subjects[key].asObservable();
    }

    return messageObservables;
  }

  private processMessage(event: MessageEvent): void {
    if (event && event.data && event.data.includes) {
      const message: Message = event.data;
      if (message.includes('Answer Loaded')) {
        const regex = /^Answer Loaded (\d+)( - Autoplay)?$/;
        const execArr: RegExpExecArray | null = regex.exec(message);
        if (execArr) {
          const answerId: number = parseInt(execArr[1], 10);
          const autoplay: boolean = execArr[2] ? true : false;
          this.subjects['Answer Loaded']?.next({ answerId, autoplay });
        }
      } else if (
        message.includes('Video id') &&
        message.includes('Widget Clicked')
      ) {
        const regex = /^Widget Clicked - Answer Index (\d+) - Length (\d+) - Answer id (\d+) - Video id (\d+)$/i;
        const execArr: RegExpExecArray | null = regex.exec(message);
        if (execArr) {
          const answerIndex: number = parseInt(execArr[1], 10);
          const answersLength: number = parseInt(execArr[2], 10);
          const answerId: number = parseInt(execArr[3], 10);
          const videoId: number = parseInt(execArr[4], 10);
          this.subjects['Widget Clicked']?.next({
            answerIndex,
            answersLength,
            answerId,
            videoId,
          });
        }
      } else if (
        !message.includes('Video id') &&
        message.includes('Widget Clicked')
      ) {
        const regex = /^Widget Clicked - Answer Index (\d+) - Length (\d+) - Answer id (\d+)$/;
        const execArr: RegExpExecArray | null = regex.exec(message);
        if (execArr) {
          const answerIndex: number = parseInt(execArr[1], 10);
          const answersLength: number = parseInt(execArr[2], 10);
          const answerId: number = parseInt(execArr[3], 10);
          this.subjects['Widget Clicked']?.next({
            answerIndex,
            answersLength,
            answerId,
          });
        }
      } else if (message.includes('Widget Loaded')) {
        // TODO Remove this dead code. Widget feed doesn't fire this event
        const regex = /^Widget Loaded - Widget id (\d+) - Client id (\d+)$/;
        const execArr: RegExpExecArray | null = regex.exec(message);
        if (execArr) {
          const widgetId: number = parseInt(execArr[1], 10);
          const clientId: number = parseInt(execArr[2], 10);
          this.store.dispatch(new WidgetActions.Set(widgetId));
          this.subjects['Widget Loaded']?.next({
            widgetId,
            clientId,
          });
        }
      } else {
        if (this.subjects[message]) {
          this.subjects[message]?.next();
        }
      }
    }
  }
}
