import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(
    value: string | null,
    ...args: [string, boolean | null | undefined]
  ): string | null {
    if (!value) {
      return value;
    }

    const textToHighlight = args[0];
    const caseSensitive = !!args[1];

    if (!textToHighlight) {
      return value;
    }

    return value.replace(
      new RegExp(textToHighlight, `g${caseSensitive ? '' : 'i'}`),
      match => `<mark class="highlight">${match}</mark>`
    );
  }
}
