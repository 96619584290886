import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreShape } from '@store/action-reducers';
import * as ApiLangActions from '@store/api-lang/api-lang.actions';
import { AvailableLang } from '@store/api-lang/api-lang.actions';

@Injectable()
export class ApiLangService {
  constructor(private store: Store<StoreShape>) {}

  setLang(lang: AvailableLang): void {
    this.store.dispatch(new ApiLangActions.Set(lang));
  }
}
