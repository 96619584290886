import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Template } from 'altru-types/@types';
import {
  CardLikedEvent,
  CardSharedEvent,
  EMPTY_CARD,
  NetworkName,
  NETWORK_MAP,
  UiCard,
} from '@shared/components';
import { ActivatedRoute } from '@angular/router';
import copyToClipboard from '@utils/copyToClipboard';
import { SnackbarService } from '@services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-card-social-buttons',
  templateUrl: './card-social-buttons.component.html',
  styleUrls: ['./card-social-buttons.component.scss'],
})
export class CardSocialButtonsComponent implements OnChanges {
  @Input()
  card: UiCard = EMPTY_CARD;

  @Input()
  tabbable = false;

  @Input()
  template: Template;

  @Output()
  share = new EventEmitter<CardSharedEvent>();

  @Output()
  like = new EventEmitter<CardLikedEvent>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public activatedRoute: ActivatedRoute,
    private snackBar: SnackbarService,
    private translateService: TranslateService
  ) {}

  checkClickEvent = checkClickEvent;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.card &&
      changes.card.previousValue?.tabbable !==
        changes.card.currentValue.tabbable
    ) {
      updateTabStops(
        this.document,
        '.sb-wrapper',
        changes.card.currentValue.tabbable
      );
    }
  }

  onShare(
    event: MouseEvent | KeyboardEvent,
    card: UiCard,
    network: NetworkName
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.share.emit({
      event,
      card,
      network,
      networkUrl: NETWORK_MAP[network],
    });
  }

  onLike(event: MouseEvent | KeyboardEvent, card: UiCard): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.checkClickEvent(event)) {
      this.like.emit({
        event,
        card,
      });
    }
  }

  async onCopyLink(
    event: MouseEvent | KeyboardEvent,
    card: UiCard
  ): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (this.checkClickEvent(event)) {
      try {
        let url = '';
        // video link "/play/video/pvbAFSzUlPhV/video_id"
        if (card.sharingLink) {
          url = `${window.origin}/play/video/${card.sharingLink
            .split('/')
            .slice(-2)
            .join('/')}`;
          // answer link "/player/answer_id"
        } else {
          url = `${window.origin}/player/${card.id}`;
        }
        await copyToClipboard(url);
      } catch (e) {
        this.snackBar.show({ message: e.message });
      } finally {
        this.snackBar.show({
          message: this.translateService.instant('VIDEO LINK COPIED'),
        });
      }
    }
  }
}

const updateTabStops = (
  document: Document,
  shareButtonSelector: string,
  tabbable: boolean
): void => {
  const shareButtons: NodeListOf<HTMLElement> = document.querySelectorAll(
    shareButtonSelector
  );
  shareButtons.forEach(button => {
    button.setAttribute('tabindex', tabbable ? '0' : '-1');
  });
};

const checkClickEvent = (event: MouseEvent | KeyboardEvent) => {
  const isKeydown = (e: Event): e is KeyboardEvent => e.type === 'keydown';
  return (
    event.type === 'click' ||
    (isKeydown(event) && event.code === 'Enter' && !event.shiftKey)
  );
};
