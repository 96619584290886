import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentEntryDirective } from './component-entry.directive';

@NgModule({
  declarations: [ComponentEntryDirective],
  imports: [CommonModule],
  exports: [ComponentEntryDirective],
})
export class ComponentEntryModule {}
