import * as R from 'ramda';

import { Video } from 'altru-types';
import * as VideosActions from './videos.actions';

type Action = VideosActions.All;

export const INITIAL_STATE: Video[] = [];

export function videosReducer(
  state: Video[] = INITIAL_STATE,
  action: Action
): Video[] {
  switch (action.type) {
    case VideosActions.SET:
      return R.clone(action.payload as Video[]);
    case VideosActions.REPLACE_VIDEO:
      const replacedVideo: Video = action.payload as Video;
      return R.update(
        R.findIndex(R.propEq('id', replacedVideo.id))(state),
        replacedVideo
      )(state);
    default:
      return state;
  }
}
