/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';

export type AvailableLang =
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it'
  | 'ja'
  | 'pt'
  | 'zh'; // TODO: Move to altru-types?
export const SET = '[Api-lang] Set';

export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: AvailableLang) {}
}

export type All = Set;
