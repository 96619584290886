import { Injectable } from '@angular/core';
import { Mixpanel } from 'mixpanel-browser';

import {
  ApplicantStatus,
  ITrackService,
  TrackEvent,
  TrackEvents,
} from '@modules/track/track.service';
import { ClientService } from '@services';
import { catchError, map, take } from 'rxjs/operators';
import { of } from 'rxjs';

const TRACKABLE_REGIONS = ['us', 'eu'] as const;
type TrackableRegion = typeof TRACKABLE_REGIONS[number];

// Mixpanel objects are created index.html
declare global {
  const mixpanel: {
    us: Mixpanel;
    eu: Mixpanel;
  };
}

@Injectable()
export class MixpanelService implements ITrackService {
  constructor(private clientService: ClientService) {}

  async track(
    action: TrackEvent,
    status: ApplicantStatus,
    properties?: object
  ): Promise<void> {
    return (await mixpanelInCurrentRegion(this.clientService))?.track(
      TrackEvents[action],
      {
        ...status,
        ...properties,
      }
    );
  }

  async register(properties: {
    $device_id?: string;
    distinct_id?: string;
    widget_version?: string;
    vs_source: string;
  }): Promise<void> {
    return (await mixpanelInCurrentRegion(this.clientService))?.register(
      properties
    );
  }
}

const isTrackableRegion = (region: string | null): region is TrackableRegion =>
  TRACKABLE_REGIONS.includes(region as TrackableRegion);

const mixpanelInCurrentRegion = (
  clientService: ClientService
): Promise<Mixpanel | null> =>
  clientService.client$
    .pipe(
      take(1),
      map(client => (client ? client.global_region ?? null : null)),
      map(globalRegion =>
        isTrackableRegion(globalRegion)
          ? mixpanel[globalRegion] ?? null
          : mixpanel.us ?? null
      ),
      catchError(e => {
        console.error('Failed to find mixpanel tracker');
        return of(null);
      })
    )
    .toPromise();
