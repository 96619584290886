import * as R from 'ramda';

import { Template } from 'altru-types';
import * as TemplateActions from './template.actions';

type Action = TemplateActions.All;

export const INITIAL_STATE: Template | null = null;

export function templateReducer(
  state: Template | null = INITIAL_STATE,
  action: Action
): Template | null {
  switch (action.type) {
    case TemplateActions.SET:
      return R.clone(action.payload as Template);
    default:
      return state;
  }
}
