import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IconsModule } from '@shared/modules/icons.module';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, TranslateModule, LazyLoadImageModule, IconsModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
