import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import * as ClientActions from '@store/client/client.actions';
import { AltruApiResponse, Client } from 'altru-types';
import { Observable } from 'rxjs';
import { StoreShape } from '@store/action-reducers';
import { RegionalApiService } from '@services';

@Injectable()
export class ClientService {
  constructor(
    private regionalApiService: RegionalApiService,
    private store: Store<StoreShape>
  ) {}

  get client$(): Observable<Client | null> {
    return this.store.select('client');
  }

  getClient(clientId: number | string): Observable<AltruApiResponse<Client>> {
    return this.regionalApiService
      .distApiGet<Client>(`clients/${clientId}`)
      .pipe(
        tap(({ data }) => {
          if (data) {
            this.storeClient(data);
            this.regionalApiService.setRegionFromClient(data);
          }
        })
      );
  }

  storeClient(client: Client): void {
    this.store.dispatch(new ClientActions.Set(client));
  }
}
