import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import * as VideosActions from '@store/videos/videos.actions';
import { AltruApiResponse, FilterVideosQueryParams, Video } from 'altru-types';
import { forkJoin, Observable } from 'rxjs';
import { StoreShape } from '@store/action-reducers';

import { RegionalApiService } from '../regional-api/regional-api.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class VideoService {
  constructor(
    private regionalApiService: RegionalApiService,
    private store: Store<StoreShape>
  ) {}

  get videos$(): Observable<Video[]> {
    return this.store.select('videos');
  }

  storeVideos(videos: Video[]): void {
    this.store.dispatch(new VideosActions.Set(videos));
  }

  updateStoredVideo(video: Video): void {
    this.store.dispatch(new VideosActions.ReplaceVideo(video));
  }

  getVideo({
    hashKey,
    hashId,
  }: {
    hashKey: string;
    hashId: string;
  }): Observable<AltruApiResponse<Video>> {
    return this.regionalApiService
      .distApiGet<Video>(`videos/${hashId}?hash_key=${hashKey}`)
      .pipe(tap(res => this.storeVideos(res.data ? [res.data] : [])));
  }

  getTemplateVideos(
    filterParams: FilterVideosQueryParams,
    templateId: number,
    clientId: number,
    isPublished: boolean = true
  ): Observable<AltruApiResponse<Video[]>> {
    const params = new HttpParams({
      fromObject: {
        client_id: clientId,
        'q[templates_id_eq]': templateId,
        ...filterParams,
      },
    });

    const videosResp$ = isPublished
      ? this.regionalApiService.distApiGet<Video[]>('videos', { params })
      : this.regionalApiService.apiGet<Video[]>('videos', { params });

    return videosResp$.pipe(
      tap(resp => this.storeVideos(resp.data ? resp.data : [])),
      take(1)
    );
  }

  likeVideo(videoId: number): any {
    const httpRequest$ = this.regionalApiService
      .apiPatch<void>(`videos/${videoId}/like`, {})
      .pipe(take(1));

    const video$ = this.videos$.pipe(
      take(1),
      map(videos => videos.find(v => v.id === videoId))
    );

    const updateStore = ({ video }: { video: Video | undefined }) => {
      if (video) {
        this.updateStoredVideo({
          ...video,
          ...{
            has_liked: true,
            // TODO: fix altru types
            likes: ((video as Video & { likes?: number }).likes ?? 0) + 1,
          },
        });
      }
    };

    forkJoin({
      video: video$,
      resp: httpRequest$,
    }).subscribe({
      next: updateStore,
    });
  }
}
