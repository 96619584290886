import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalize' })
export class NormalizePipe implements PipeTransform {
  transform(text: string): string {
    return text
      .split('_')
      .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' ');
  }
}
