/* eslint-disable max-classes-per-file */

import { Action } from '@ngrx/store';
import { Client } from 'altru-types';

export const SET = '[Client] Set';

export class Set implements Action {
  readonly type: string = SET;
  constructor(public payload: Client) {}
}

export type All = Set;
