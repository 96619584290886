import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Answer } from 'altru-types';
import { AnswerService } from '@services';

@Injectable()
export class AnswerGuardService implements CanActivateChild {
  constructor(private router: Router, private answerService: AnswerService) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    const answerId: number = route.params.answer_id;
    const storedAnswer$ = this.answerService.answers$.pipe(
      first(),
      map(answers => answers[0])
    );

    return storedAnswer$.pipe(
      switchMap(storedAnswer =>
        storedAnswer && storedAnswer.id === answerId
          ? of(true)
          : this.answerService.getAnswer(answerId).pipe(
              map(answerRes => {
                const answer: Answer | undefined = answerRes.data;
                if (answer && answer.id === +answerId) {
                  return true;
                } else {
                  this.router.navigate(['/not-found']);
                  return false;
                }
              }),
              catchError(_ => {
                this.router.navigate(['/not-found']);
                return of(false);
              })
            )
      )
    );
  }
}
