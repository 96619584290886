import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailViewComponent } from './detail-view.component';
import { CardModule } from '@shared/components/card/card.module';
import { VideoPlayerModule } from '@shared/components/video-player/video-player.module';

@NgModule({
  declarations: [DetailViewComponent],
  imports: [CommonModule, CardModule, VideoPlayerModule],
  exports: [DetailViewComponent],
})
export class DetailViewModule {}
