import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { transcriptionLabel } from 'altru-types/@types';

@Component({
  selector: 'altru-video-controls',
  templateUrl: './video-controls.component.html',
  styleUrls: ['./video-controls.component.scss'],
})
export class VideoControlsComponent {
  @Input() isPlaying = false;
  @Input() videoHeight = 0;
  @Input() duration = 0;
  @Input() volume = 1;
  @Input() currentTime = 0;
  @Input() localeKeys = [];
  @Input() localeLabels = {};
  @Input() loadedMetadata = false;
  @Input() transcript = '';
  @Input() minVideoSize = { minWidth: true, minHeight: true };
  @Input() captionsEnabled = false;
  @Input() captionLang: transcriptionLabel = this.localeKeys[0];

  @Output()
  playVideo: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  pauseVideo: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  changeCurrentTime: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  changeVideoVolume: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  enableFullScreen: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  enablePictureInPicture: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  setVideoTrack: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  @Output() toggledCaptions: EventEmitter<boolean | null> = new EventEmitter<
    boolean | null
  >();
  @Output()
  selectedCaption: EventEmitter<transcriptionLabel | null> = new EventEmitter<transcriptionLabel | null>();

  @HostBinding('class.small')
  @Input()
  small = false;

  pictureInPictureEnabled =
    isPlatformBrowser(this.platformId) && 'pictureInPictureEnabled' in document;

  constructor(@Inject(PLATFORM_ID) private platformId) {}
}
