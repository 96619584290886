import { ActionReducer } from '@ngrx/store';
import { StoreShape } from './action-reducers';
import * as Answers from './answers/answers.reducer';
import * as ApiLang from './api-lang/api-lang.reducer';
import * as Client from './client/client.reducer';
import * as Identity from './identity/identity.reducer';
import * as Me from './me/me.reducer';
import * as Template from './template/template.reducer';
import * as Videos from './videos/videos.reducer';
import * as Widget from './widget/widget.reducer';
import * as Ui from './ui/ui.reducer';
import * as StoreActions from './store.actions';

type Action = StoreActions.All;

const INITIAL_STATE: StoreShape = {
  answers: Answers.INITIAL_STATE,
  apiLang: ApiLang.INITIAL_STATE,
  me: Me.INITIAL_STATE,
  client: Client.INITIAL_STATE,
  template: Template.INITIAL_STATE,
  identity: Identity.INITIAL_STATE,
  videos: Videos.INITIAL_STATE,
  widget: Widget.INITIAL_STATE,
  ui: Ui.INITIAL_STATE,
};

export function metaReducer(
  reducer: ActionReducer<StoreShape>
): ActionReducer<StoreShape> {
  return (state: StoreShape, action: Action): StoreShape => {
    switch (action.type) {
      case StoreActions.CLEAN:
        return reducer(INITIAL_STATE, action);
      default:
        return reducer(state, action);
    }
  };
}
