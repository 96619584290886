import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConsoleModule } from './console/console.module';
import { MixpanelModule } from './mixpanel/mixpanel.module';
import { SnowplowModule } from './snowplow/snowplow.module';
import { TrackService } from './track.service';

@NgModule({
  imports: [MixpanelModule.forRoot(), ConsoleModule, SnowplowModule],
  providers: [TrackService],
})
export class TrackModule {
  static forRoot(): ModuleWithProviders<TrackModule> {
    return {
      ngModule: TrackModule,
      providers: [TrackService],
    };
  }
}
